import React, { FC, Fragment, useState, useEffect, useRef,FormEvent,useContext  } from "react";
import { NavLink,useLocation } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import axios from 'axios';
import RewardCard from "./RewardCard";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed
export interface CommonLayoutProps {
  Displayname?:string;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children,Displayname }) => {
  
  const [users, setUser] = useState<any>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null; // Load from localStorage if available
  });
  const [images, setImages] = useState<File[]>([]);
  const [imageURLs, setImageURLs] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploaded, setIsUploaded] = useState(true);
  const selectForm = useRef<HTMLFormElement>(null);
  const { user, logout, updateUser } = useAuth();
  const location = useLocation(); // ✅ Get the current URL path

  const isActive = (path: string) => {
    return location.pathname === path ? "active" : "";
  };
  
  useEffect(() => {
    if (images.length < 1) return;
    const url = URL.createObjectURL(images[0]);
    setImageURLs(url);
    return () => URL.revokeObjectURL(url);
  }, [images]);

 

  const onImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
   // console.log("Files selected:", e.target.files);
    const filesArray = Array.from(e.target.files);
    setImages(filesArray);
    const file = filesArray[0];
    setSelectedFile(file);
    //console.log("Selected file:", file);
    e.target.value = "";
    if (file.size ) {
      const formData = new FormData();
      formData.append("selectedFile", file);
      try {
          setIsUploaded(false);
          axios.post(process.env.REACT_APP_BASE_URL +"changeProfilePic?user_id="+user?.user_id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        }).then(response=> {
          setIsUploaded(true);
          //const newProfileImage = response.data.user.profile_image;
            /*setUser((prevUser: any) => {
              const updatedUser = { ...prevUser, profile_image: newProfileImage };
              //localStorage.setItem("user", JSON.stringify(updatedUser));
              updateUser("profile_image",newProfileImage);
              return updatedUser;
            });*/
            updateUser(response.data.user);
        })
        .catch(error => {
          //console.error("Error updating user info", error);
        });;
        
      } catch (error) {
        ///console.error("Upload error:", error);
      }
    }
    
  };
  
  return (
  <div className="left-user-details flex-wrap h-1/2">
    <div className="Avatar-profile ">
    <form ref={selectForm} className="" onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()} autoComplete="off">
      {isUploaded ?
      <Avatar  avatarImage={user?.user_profile_pic}></Avatar>
      :<div className="skeleton-loader-circle"></div>
      }
      <span className="icon-uplod"><i className=" las la-edit"></i> Add
      <input type="file" onChange={onImageChange} />
      </span>
    </form>
    </div>
    <h4 className="mt-4 pt-5">{user?.display_name}</h4>
    <RewardCard progress={user?.user_rewards.progress} points={user?.user_rewards.points} tier={user?.user_rewards.tier} />
    <p>Complete your profile to receive Tiny Away points for your next getaway.</p>
    <div className="nav-left-page mt-5">
      <ul>
      <li>
          <a href="/account" className={`flex gap-2 items-center ${isActive("/account")}`}>
            <span><i className="las la-user"></i> MY PROFILE </span>
          </a>
      </li>
      <li>
          <a href="/promocode" className={`flex gap-2 items-center ${isActive("/promocode")}`}>
            <span><i className="las la-ticket-alt"></i> PROMO CODE</span> <i className="las la-plus"></i>
          </a>
      </li>
        {/* <li><a href="/our-rewords" className="flex gap-2 items-center"><span><i className="las la-gift"></i> REWARDS</span></a></li> */}
      <li>
          <div className="line"></div>
      </li>
      <li>
          <a href="/reservations" className={`flex gap-2 items-center ${isActive("/reservations")}`}>
            <span><i className="las la-calendar-day"></i> RESERVATIONS</span>
          </a>
      </li>
      <li>
          <a href="/wishlists" className={`flex gap-2 items-center ${isActive("/wishlists")}`}>
            <span><i className="las la-heart"></i> WISHLISTS</span>
          </a>
      </li>
      <li>
          <a href="/giftcardsredeem" className={`flex gap-2 items-center ${isActive("/giftcardsredeem")}`}>
            <span><i className="las la-gifts"></i> GIFT CARD</span>
          </a>
      </li>
      {/* <li><a href="/Pagereferralprogram" className="flex gap-2 items-center"><span><i className="las la-bullhorn"></i> REFERRAL PROGRAM</span></a></li> */}
      {/* <li><a href="/pagemypost" className="flex gap-2 items-center"><span><i className="las la-image"></i> MY POST</span></a></li> */}
      <li>
          <div className="line"></div>
      </li>
      {/* <li><a href="/account" className="flex gap-2 items-center"><span><i className="las la-user-edit"></i> EDIT PROFILE</span></a></li> */}
      <li>
          <a href="javascript:void(0)" onClick={logout} className="flex gap-2 items-center">
              <span><i className="las la-sign-out-alt"></i> LOGOUT</span>
          </a>
      </li>
      </ul>
    </div>
  </div>
  );
};
export default CommonLayout;
