import React, { FC, ReactNode , useEffect, useState }  from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "../PageHome/HeaderFilter";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import axios from 'axios'; 
import BackgroundSection from "components/BackgroundSection/BackgroundSection";


// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeatureStoryProps {
  stayStories?: ExperiencesDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  listing_id?:string;
}

const SectionGridFeatureStory: FC<SectionGridFeatureStoryProps> = ({
  stayStories = DEMO_DATA,
  gridClass = "",
  heading = "Tiny Stories",
  subHeading = "Hear it from our guests. Here is a list of stories from people that have experienced a stay on this property",
  headingIsCenter,
  tabs = [],
  listing_id
}) => {
  const [stories, setStories]                     = useState([]); 
  const [isdataLoaded, setIsdataLoaded]           = useState(false);
  const [customheading, setCustomheading]         = useState(heading); 
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'/GetFeaturedSimilarStories?listing='+listing_id)
 	 .then(res => {
          setStories(res.data.stories); 
          if(res.data.stories.length > 0){
            setIsdataLoaded(true);
          }
          setCustomheading(res.data.heading);
    });   
  }, []);
  //console.log(stories); 
  const renderCard = (stay: ExperiencesDataType) => {
    return <ExperiencesCard key={stay.id} data={stay} />;
  };
  return (
    <>
      {isdataLoaded ? 
    <div className="relative py-0 mb-6">
     <div className="nc-ExperiencesCard relative">
     
     <div
        className={`grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-2 ${gridClass}`}
      > 
        {stories && stories.length!=0 ?
        
        stories.map((stay) => renderCard(stay)) 
        
        : ''
        }
      </div>
    </div>
    </div>
      :''
      }
    </>
  );
};

export default SectionGridFeatureStory;
