import React, { FC,useState,useEffect,ComponentType } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios'; 

export interface PageFaqProps {
  className?: string;
}
const PageFaq: FC<PageFaqProps> = ({
  className = "" 
}) => {

  const [description, setDescription]                         = useState("");
  const [metaTitle, setMetaTitle]                             = useState("");
  const [bannnerUrl, setBannnerUrl]                           = useState("");
  const [isVideo, setIsVideo]                                 = useState("");
  const [isImages, setIsImages]                               = useState("");
  const [isdataLoaded, setIsdataLoaded]                       = useState(false);


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getPages?page=faq')
  .then(res => {
    setDescription(res.data.page_details.content_html);
    setMetaTitle(res.data.page_details.meta_title);
    setBannnerUrl(res.data.page_details.banner_url);
    setIsVideo(res.data.page_details.is_video);
    setIsImages(res.data.page_details.is_images);
    setIsdataLoaded(true);
  })
}, [isdataLoaded]);
const renderskeletonLoader = () => {
  return (
    <div className="custom-loader"><div className="h-screen skeleton"></div></div>
  );
};
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout">
      <Helmet title={metaTitle}></Helmet>
      <BgGlassmorphism />
      {isdataLoaded  ?
      <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
       {isVideo=='yes'?
          <video width="100%"  loop autoPlay={true} height="100%" controls className="group  bg-neutral-800 rounded-l overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px]" > 
          <source src={bannnerUrl} type="video/mp4"/> </video>
        :
          ""
        }
        {isImages=='yes'?
          <img className="w-full" src={bannnerUrl} alt="hero" />
        :
          ""
        }
      <div className="our-tiny p-0 pl-0">
          <div className="common-content-descriptions"  dangerouslySetInnerHTML={ {__html: description} } />
      </div>
      </div>
      :
      <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
        {renderskeletonLoader()}
      </div>
      }
    </div>
  );
};

export default PageFaq;
