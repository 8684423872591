import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSliderNewCategoriesFeaturedIn from "components/SectionSliderNewCategoriesFeaturedIn/SectionSliderNewCategoriesFeaturedIn";
import SectionAibnbReviews from "components/SectionAibnbReviews/SectionAibnbReviews";
import React, { FC, Fragment,ReactNode,useEffect,useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionGridFeatureStory from "./SectionGridFeatureStory";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";
import axios from 'axios'; 
import { Route, Redirect,useHistory} from 'react-router-dom';

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/BI_blue_background_vertical.png",
  },
  {
    id: "2",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/761ec63ba823cd88446152f20d7d8183.png",
  },
  {
    id: "3",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/7-NEWS-black.png",
  },
  {
    id: "4",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Stuff.png",
  },
  {
    id: "5",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Urban-List.png",
  },
  {
    id: "6",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/timeout-cs-logo-img-1.jpg",
  },
  {
    id: "7",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/the-weekend-Australian-magazine-1.png",
  },
  {
    id: "8",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/daily-telegraph-logo-vector-i1-1.png",
  },
  {
    id: "9",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/tfe-logo-768x123-2.png",
  }
];

const DEMO_CATS_2: TaxonomyType[] = [];
const AIRBNB_REVIEWS: TaxonomyType[] = [];
function PageHome() {
  const [DEMO_CATS_2ww, setDiscovery]               = useState();
  const [isWebsiteName, setWebsiteName]             = useState('tinyaway');
  const [socialLinks, setSocialLinks]               = useState({facebook:"",instagram:"",youtube:""});
  const [metaDetails, setMetaDetails]               = useState({meta_title:"",meta_desc:""});
  const history                                     = useHistory();
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'/GetTravelInspirations')
    .then(res => {
          //console.log(DEMO_CATS_2ww); 
          //console.log(res.data); 
          setDiscovery(res.data.data);
          setWebsiteName(res.data.isWebsiteName);
          setSocialLinks(res.data.social_links);
          setMetaDetails(res.data.metaDetails);
    });   
  }, []);

  const [AIRBNB_REVIEWS, setAirbnbReviews] = useState();
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'GetAirbnbReviews')
    .then(res => {
          setAirbnbReviews(res.data);
    });   
  }, []);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const showOnMap = () => {
      setShowFullMapFixed(true);
      history.push("/listing-stay-map");
  }
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>{metaDetails.meta_title}</title>
      </Helmet>
      <BgGlassmorphism />
      <SectionHero className="pt-10 lg:pt-24 pb-0" />
      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <div className="relative py-0 mt-20 mb-0">
          <SectionGridFeaturePlaces />
          <div className="flex justify-center mt-10">
          <a href="/book-a-tiny-house" className="text-opacity-90
 group px-6  uppercase py-4 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-white">EXPLORE ALL OUR PROPERTIES</a>
          </div>
        </div>
        <SectionGridFeatureStory  />
        <div className="relative py-0">
          <BackgroundSection className="bg--50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2ww}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Travel Inspirations"
            subHeading="Popular things to do that Tiny Away recommends for you"
            sliderStyle="style2"
          />
        </div>
      </div>
      <SectionAibnbReviews
        className="hedding-center line-bottom-0"
        subHeading=""
        categoryCardType="card7"
        categories={AIRBNB_REVIEWS}
        itemPerRow={1}
      />
      <SectionAibnbReviews
        heading="featured In "
        subHeading=""
        className="hedding-center sm-hedding-text"
        categoryCardType="card6"
        categories={DEMO_CATS}
        itemPerRow={5}
      />
      <SectionSubscribe2 />
      {/* {!showFullMapFixed && isWebsiteName=='tinyaway' && (
          <div
            className="flex  items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => showOnMap()}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )} */}
    </div>
  );
}
export default PageHome;
