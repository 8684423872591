import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import React, { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Collapsible from 'react-collapsible';

export interface ListingExperiencesPageProps {
  className?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Enjoy the Beauty of Brazil ",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/1118877/pexels-photo-1118877.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  }
];
const renderRatesDetails = () => {
  return (
    <div className="details-page-sub-header">
      <Collapsible trigger="FEATURES OF OUR TINY HOUSES" open={true}>
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            <p>No matter the location of tiny house you are interested in renting from us, you will find the following features in most of the tiny houses, except for a few: </p>
            <p>A kitchenette with all you need to prepare your light meals, including a cookware, portable cooker, cooking utensils, dishes & silverware, cutlery, microwave, and a mini-fridge. A bathroom that operates in an ecologically sustainable principle, comprising of a waterless environmentally friendly compost toilet, shower (gas heated for hot showers), hand basin.</p>
            <p> Air conditioning</p>
            <p>A comfy queen sized bed with fresh bed sheets.</p>
            <p>A lounge area to chill and enjoy nature at its best.</p>
            <p>Here at Tiny Away, we also have pet-friendly cabins for guests who would love a vacation with their pets. We encourage guests booking with their pets to inform the host. Our tiny houses are built to give you the best comfort and convenience while escaping from the hectic urban life.</p>
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="WHY TINY HOUSE GETAWAY">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            <p>No matter the location of tiny house you are interested in renting from us, you will find the following features in most of the tiny houses, except for a few: </p>
            <p>A kitchenette with all you need to prepare your light meals, including a cookware, portable cooker, cooking utensils, dishes & silverware, cutlery, microwave, and a mini-fridge. A bathroom that operates in an ecologically sustainable principle, comprising of a waterless environmentally friendly compost toilet, shower (gas heated for hot showers), hand basin.</p>
            <p> Air conditioning</p>
            <p>A comfy queen sized bed with fresh bed sheets.</p>
            <p>A lounge area to chill and enjoy nature at its best.</p>
            <p>Here at Tiny Away, we also have pet-friendly cabins for guests who would love a vacation with their pets. We encourage guests booking with their pets to inform the host. Our tiny houses are built to give you the best comfort and convenience while escaping from the hectic urban life.</p>
           </div>
          </div> 
          </div>
      </Collapsible>

      <Collapsible trigger="WHY CHOOSE US">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            <p>No matter the location of tiny house you are interested in renting from us, you will find the following features in most of the tiny houses, except for a few: </p>
            <p>A kitchenette with all you need to prepare your light meals, including a cookware, portable cooker, cooking utensils, dishes & silverware, cutlery, microwave, and a mini-fridge. A bathroom that operates in an ecologically sustainable principle, comprising of a waterless environmentally friendly compost toilet, shower (gas heated for hot showers), hand basin.</p>
            <p> Air conditioning</p>
            <p>A comfy queen sized bed with fresh bed sheets.</p>
            <p>A lounge area to chill and enjoy nature at its best.</p>
            <p>Here at Tiny Away, we also have pet-friendly cabins for guests who would love a vacation with their pets. We encourage guests booking with their pets to inform the host. Our tiny houses are built to give you the best comfort and convenience while escaping from the hectic urban life.</p>
           </div>
          </div> 
          </div>
      </Collapsible>


    </div>
  )
}


const ListingExperiencesPage: FC<ListingExperiencesPageProps> = ({
  className = "",
}) => {
  return (
    <div className={`bg-color-gry nc-ListingExperiencesPage relative overflow-hidden ${className}`} data-nc-id="ListingExperiencesPage">
      <div className=" w-full">
            <div className="tw-banner ">
              <img className="w-full" src="https://pictures.tinyaway.com/filters:format(jpg)/1960x820/Banners/story/_MG_7827.jpg" alt=""></img>
              <div className="main-about-banner">
                <div>
                  <h1><span>TINY AWAY STORIES</span></h1>
                  <p>Discover destinations, curating experiences. These are stories on our tiny houses to help you find out what to do in each tiny house.</p>
                </div>
              </div>
            </div>
          </div>
      <div className="container relative overflow-hidden  container-serch-form-full">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Experiences"
          currentTab="Experiences"
          pageHeadingTitle="Tiny House Stories"
          listingType={
            <>
              {/*<i className="text-2xl las la-umbrella-beach"></i>
              <span className="ml-2.5">1599 experiences</span>*/}
            </>
            
          }
          className="pt-10 pb-0 lg:pb-3 lg:pt-0 "
        />

        {/* SECTION */}
        <SectionGridFilterCard className="pb-24 lg:pb-32" />

        {/* SECTION 1 */}
         {/*<div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore top destination ✈"
            subHeading="Explore thousands of destinations around the world"
            categoryCardType="card4"
            itemPerRow={4}
            categories={DEMO_CATS}
            sliderStyle="style2"
          />
        </div>*/}

        {/* SECTION */}
        
      </div>
      <div className="bg-white hidden">
        <div className="container">
          <div className="flex flex-fetures">
            <div className="w-30">
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/960x720/Stories/Images/2024/01/211/CloudValleyEscapeCumulus-251705713000251.jpg" />
            </div>
            <div className="flex flex-direction-column items-center justify-start space-x-3 text-left">
              {renderRatesDetails()}
            </div>
          </div>
          </div>
          </div>


      <SectionSubscribe2 />
          

    </div>
  );
};

export default ListingExperiencesPage;
