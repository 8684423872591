import React, { FC, Fragment,useState,useEffect,useRef,useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import facebookSvg from "images/Facebook.svg";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios';  
import { Route, Redirect,useHistory} from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { AuthContext } from "AuthContext/AuthContext";


export interface PageLoginProps {
  className?: string;
  autoFocus?: boolean;
}
const PageLogin: FC<PageLoginProps> = ({ 
  className = "",
  autoFocus = false,
}) => {
  const history                                                 = useHistory();
  const [errorMsg, setErrorMsg]                                 = useState("");
  const [error,setError]                                        = useState("");
  const [successMsg, setSuccessMsg]                             = useState('');
  const [success,setsuccess]                                    = useState('');
  const [logind, setLogin]                                       = useState(false);
  const [data, setData]                                         = useState({});
  const [details,setDetails]                                    = useState({email:"",password:""});
  const [showPopover, setShowPopover]                           = useState(autoFocus);
  const emailRef                                                = useRef<HTMLInputElement>(null);
  const passwordRef                                             = useRef<HTMLInputElement>(null);
  const clientId                                                = process.env.REACT_APP_GMAIL_CLIENT_ID;
  const facebookAppID                                           = process.env.REACT_APP_FACEBOOK_APP_ID;
  const [isModalForgotPassword, setIsModalForgotPassword]       = useState(false);
  const [detailsForgorPassword,setDetailsForgorPassword]        = useState({username:""});
  const ForgorPasswordRef                                       = useRef<HTMLInputElement>(null);
    // ✅ Ensure `AuthContext` is not undefined
  const authContext = useContext(AuthContext);
  const login = authContext?.login;
  const loginWithGoogle = authContext?.loginWithGoogle;
  const loginWithFacebook = authContext?.loginWithFacebook;
  
  useEffect(() => {
    gapi.load("client:auth2",()=>{
      gapi.auth2.init({clientId:clientId})
    })
  },[]);
  const validateInput = () => {
    const fields = [
      {
        name: 'email',
        value: details.email,
        message: 'Email should not be blank.'
      },
      {
        name: 'password',
        value: details.password,
        message: 'Password should not be blank.'
      }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        //field.name === 'email' ? emailRef.current.focus() : passwordRef.current.focus();
        return true;
      }
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isInvalid = validateInput();
    if (!isInvalid) {
      try {
        if (login) {
          const response   = await login(details.email, details.password);  // ✅ Use `login` from AuthContext
          if(response.success){
            if(response.error){
              setSuccessMsg(response.error);
              history.push("/account"); 
            }
          }else{
            if(response.error)
            setErrorMsg(response.error);
          }
        } else {
          setErrorMsg("Authentication service is unavailable.");
        }
      } catch (error) {
        setErrorMsg("Invalid credentials. Please try again.");
      }
    } else {
      setSuccessMsg('');
    }
  }
  const responseFacebook = async (response_fb:any) => {
    if (response_fb.accessToken) {
      if(loginWithFacebook){
        const response  = await loginWithFacebook(response_fb);
        if(response.success){
            if(response.error){
              setSuccessMsg(response.error);
              history.push("/account"); 
            }
          }else{
            if(response.error)
            setErrorMsg(response.error);
          }
      }
      
    }
    /*
    if (response_fb.accessToken) {
      axios.post(process.env.REACT_APP_BASE_URL+'UserRegistrationbySocial', {
        name        :response_fb.name,
        first_name  :response_fb.first_name,
        last_name   :response_fb.last_name,
        accessToken :response_fb.accessToken,
        email       :response_fb.email,
        userID      :response_fb.userID,
        picture     :response_fb.picture.data.url,
      })
    .then(function (response) {
      if(response.data.status==200){
        setsuccess('Successfully register');
        setSuccessMsg('Successfully register');
        //setDetails({first_name:"",last_name:"",email:"",password:""});
        localStorage.setItem("user_id",response.data.user_details.user_id);
        localStorage.setItem("user",response.data.user_details);
        history.push("/");
      }else{
        setError(response.data.response);
      }
    })
    .catch(function (error) {
    });
      setLogin(true);
    } else {
      setLogin(false);
    }*/
  }
  const responseGoogle = async (response_gmail:any) => {
    if (response_gmail.accessToken) {
      if(loginWithGoogle){
        const response  = await loginWithGoogle(response_gmail);
        if(response.success){
          if(response.error){
            setSuccessMsg(response.error);
            history.push("/account"); 
          }
          
        }else{
          if(response.error)
          setErrorMsg(response.error);
        }
      }
      
    }
    /*
    if (response_gmail.accessToken) {
      axios.post(process.env.REACT_APP_BASE_URL+'UserRegistrationbySocial', {
        name        :response_gmail.profileObj.name,
        first_name  :response_gmail.profileObj.givenName,
        last_name   :response_gmail.profileObj.familyName,
        accessToken :response_gmail.accessToken,
        email       :response_gmail.profileObj.email,
        userID      :response_gmail.profileObj.googleId,
        picture     :response_gmail.profileObj.imageUrl,
        platform    :"gmail"
      })
    .then(function (response) {
      if(response.data.status==200){
        setsuccess('Successfully register');
        setSuccessMsg('Successfully register');
        //setDetails({first_name:"",last_name:"",email:"",password:""});
        localStorage.setItem("user_id",response.data.user_details.user_id);
        localStorage.setItem("user",response.data.user_details);
        history.push("/");
      }else{
        setError(response.data.response);
        setErrorMsg(response.data.response);
      }
    })
    .catch(function (error) {
    });
      setLogin(true);
    } else {
      setLogin(false);
    }*/
  }
  const submitHandlerForgotPWD = (event: React.FormEvent<HTMLFormElement>) =>{
    event.preventDefault();
    const isInvalid = validateForgotPwdInput();
    if (!isInvalid) {
      axios.post(process.env.REACT_APP_BASE_URL+'forgot_password', detailsForgorPassword)
    .then(function (response) {
      if(response.data.status==200){
        setDetailsForgorPassword({username:""});
        setsuccess(response.data.response);
      }else{
        setDetailsForgorPassword({username:""});
        setError(response.data.response);
      }
    })
    .catch(function (error) {
    });
    } else {
      setsuccess('');
    }
  };
  function closeModalForgotPassword() {
    setIsModalForgotPassword(false);
  }
  function openModalForgotPassword() {
    setIsModalForgotPassword(true);
  };
  const renderMotalForgotPWD = () => {
    return (
      <Transition appear show={isModalForgotPassword} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto model-sm-min"
          onClose={closeModalForgotPassword}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
  
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen  w-full sm:w-xl Coupons-model">
                <div className="inline-flex flex-col w-1/2 max-w-xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-1/2">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Forgot password
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalForgotPassword} />
                    </span>
                  </div>
                  <div className="px-8 text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div className="container mt-5">
                    <div className="max-w-md mx-auto space-y-6">
                      {/* <div className="grid gap-3">
                      </div> */}
                      {success && 
                      <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                      {success}
                      </div>}
                      {error && 
                      <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                      {error}</div>}
                      {/* FORM */}
                      <form className="grid grid-cols-1 gap-6" onSubmit={submitHandlerForgotPWD}>
                      <label className="block">
                          {/* <span className="text-neutral-800 dark:text-neutral-200">
                            Name
                          </span> */}
                          <Input
                            type="text"
                            placeholder="Enter Email or username"
                            className="mt-1"
                            name="username"
                            id="username"
                            onChange={event=>setDetailsForgorPassword({...detailsForgorPassword,username:event.target.value})}
                            ref={ForgorPasswordRef}
                            autoComplete="off"
                            autoFocus={showPopover}
                            value={detailsForgorPassword.username}
                          />
                        </label>
                        <ButtonPrimary type="submit">Submit</ButtonPrimary>
                      </form>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const validateForgotPwdInput = () => {
    const fields = [
      {
        name: 'name',
        value: detailsForgorPassword.username,
        message: 'Emai or user name not be blank.'
      }
    ];
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setError(field.message);
        //field.name === 'email' ? emailRef.current.focus() : passwordRef.current.focus();
        return true;
      }
      setError('');
      return false;
    });
    return isNotFilled;
  };
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login to Tiny Away | Recommended Tiny House Accommodation</title>
      </Helmet>
      <div className="container">
        <div className="flex flex-login mt-4">
        <div className="w-1/2">
        <img className="h-full" src="https://pictures.tinyaway.com/filters:format(jpg)/960x720/Stories/Images/2024/06/226/IMG_23791719016086252.jpg"/>
        </div>
        <div className="w-1/2 p-2.5">
        <h2 className="uppercase">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
          <FacebookLogin
              appId="752989346684469"
              autoLoad={false}
              fields="email,name,picture,first_name,last_name"
              scope="public_profile,email"
              callback={responseFacebook}
              version="3.1"
              cookie={false}
              icon="fa-facebook"
              render={renderProps => (
                <a 
                href="#"
                className="facebook-login"
                onClick={renderProps.onClick}
              >
                  <img
                  className="flex-shrink-0"
                  src={facebookSvg}
                  alt="Continue with Facebook"
                />   Continue with Facebook
                

              </a>
              )}
              />
              <GoogleLogin
              clientId={clientId}
              render={renderProps => (
                <button 
                className="gmail-login"
                onClick={renderProps.onClick} disabled={renderProps.disabled}>
                <img
                  className="flex-shrink-0"
                  src={googleSvg}
                  alt="Continue with Google"
                />
                  Continue with Google
                 
                </button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {successMsg && 
          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
          {successMsg}
          </div>}
          {errorMsg && 
          <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
          {errorMsg}</div>}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6"  onSubmit={submitHandler}>
            <label className="block">
              
              <Input
                type="text"
                placeholder="EMAIl"
                className="mt-1"
                name="email"
                id="email"
                onChange={event=>setDetails({...details,email:event.target.value})}
                ref={emailRef}
                autoComplete="off"
                autoFocus={showPopover}
                value={details.email}
              />
            </label>
            <label className="block">
              
                
              <Input 
                type="password" 
                 placeholder="PASSWORD"
                className="mt-1"
                name="password"
                id="password"
                value={details.password}
                onChange={event=>setDetails({...details,password:event.target.value})}
                ref={passwordRef}
                autoComplete="off"
                autoFocus={showPopover}
              />
            </label>
            <ButtonPrimary type="submit" className="w-1/100 m-auto Source-Sans-3 btn-bold">LOGIN</ButtonPrimary>
          </form>

          {/* ==== */}
          <div className="flex flex-direction-column gap-3 justify-center text-center password-create-acc">
          <a href={void(0)} onClick={openModalForgotPassword} className="text-sm">
                  Forgot password?
                </a>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
          </div>
        </div>
      </div>
      </div>
      </div>
      {renderMotalForgotPWD()}
    </div>
  );
};

export default PageLogin;
