import React from "react";
import Textarea from "shared/Textarea/Textarea";
import Checkbox from "shared/Checkbox/Checkbox";

interface CommonPaymentFieldsProps {
  paymentMessage: string;
  setPaymentMessage: (message: string) => void;
  cancellationPolicyText?: string;
}

const CommonPaymentFields: React.FC<CommonPaymentFieldsProps> = ({ paymentMessage, setPaymentMessage, cancellationPolicyText }) => {
  return (
    <div className="submet-cart-info">
      {/* 🔹 Cancellation Policy Message */}
      {cancellationPolicyText && (
        <>
          <h4>CHANGE OF PLANS? {cancellationPolicyText}</h4>
          <p className="mt-2">1. Full refund for cancellations is allowed <b>at least 14 days before the check-in date</b></p>
          <p>2. No refund for cancellations made <b>less than 14 days of check-in</b></p> 

        </>
      )}

      {/* 🔹 Textarea for Additional Message */}
      <Textarea
        className="mt-4 mb-4"
        placeholder="WRITE A MESSAGE"
        value={paymentMessage}  // ✅ Controlled Input
        onChange={(e) => setPaymentMessage(e.target.value)}  // ✅ Update state on change
      />

      {/* 🔹 Terms and Conditions Checkbox */}
      <Checkbox
        defaultChecked
        className="text-xs xl:text-sm text-neutral-800 mr-2"
        name="terms_conditions"
        label="By completing your booking, you’re agreeing to the Tiny Away's Terms & Conditions and paying the total booking fee."
      />
    </div>
  );
};

export default CommonPaymentFields;
