import rightImg from "images/about-hero-right.png";
import React, { FC,useState,useEffect,ComponentType,useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "../PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios'; 
import Collapsible from 'react-collapsible';
export interface Pagecovid19policyProps {
  className?: string;
}
const Pagecovid19policy: FC<Pagecovid19policyProps> = ({ className = "" }) => {

  const [description, setDescription]                         = useState("");
  const [metaTitle, setMetaTitle]                             = useState("");
  const [bannnerUrl, setBannnerUrl]                           = useState("");
  const [isVideo, setIsVideo]                                 = useState("");
  const [isImages, setIsImages]                               = useState("");
  const [isdataLoaded, setIsdataLoaded]                       = useState(false);
  const [updatedDate, setUpdatedDate]                         = useState("");
  const section1Ref = useRef<HTMLDivElement | null>(null);
  const section2Ref = useRef<HTMLDivElement | null>(null);
  const section3Ref = useRef<HTMLDivElement | null>(null);
  const section4Ref = useRef<HTMLDivElement | null>(null);
 const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getPages?page=cancellation_policy')
  .then(res => {
    setDescription(res.data.page_details.content_html);
    setMetaTitle(res.data.page_details.meta_title);
    setBannnerUrl(res.data.page_details.banner_url);
    setIsVideo(res.data.page_details.is_video);
    setIsImages(res.data.page_details.is_images);
    setUpdatedDate(res.data.page_details.date_of_modified);
    setIsdataLoaded(true);
  })
}, [isdataLoaded]);
const renderFaqNav = () => {
  return (
    <div  className="nav-faq">
        <div className="nav-w20">  <h1>FAQ</h1></div>
        <div className="menu-faq">
          <a href="javasctipt:void(0)" onClick={() => scrollToSection(section1Ref)}>BOOKING PROCESS</a>
          <a href="javasctipt:void(0)" onClick={() => scrollToSection(section2Ref)}>CHECK-IN AND STAY</a>
          <a href="javasctipt:void(0)" onClick={() => scrollToSection(section3Ref)}>COMMUNICATION</a>
          <a href="javasctipt:void(0)" onClick={() => scrollToSection(section4Ref)}>PAYMENTS AND REFUNDS</a>
          <a href=""><i className="las la-search text-lg"></i> SERACH</a>
        </div>
      </div>
  );
};
const renderBookingProcess = () => {
  return (
    <div className="BookingProcess faq-comman-class"  ref={section1Ref}>
      <h2>BOOKING PROCESS</h2>
      <div>
      <Collapsible trigger="1. WHY CHOOSE US" open={true}>
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. <br></br>
            Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart <br></br>
            Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>

      <Collapsible trigger="2. Can I book multiple listings for the same dates?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="3. Are all tiny houses suitable for children?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="5. Can I send an inquiry before booking a reservation?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="6. Do you accept long term bookings?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="7. What is your cancellation policy?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="8. Can I modify or change by booking dates after confirmation?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="9. How can I request to cancel or modify my reservation?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>

      <Collapsible trigger="10. Can I use two or more vouchers/gift cards in one booking?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>

      </div>
    </div>
  );
};
const renderCheckINAndStay = () => {
  return (
    <div className="BookingProcess faq-comman-class" ref={section2Ref}>
      <h2>CHECK-IN AND STAY</h2>
      <div>
      <Collapsible trigger="1. Is early check-in and late check-out possible?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Early check-in and late check-out requests are subject to availability, depending if the tiny house is booked the day before or on your departure date. You may reach out to us to check closer to your arrival date. </div>
          </div> 
          </div>
      </Collapsible>
 
      <Collapsible trigger="2. Are linens and towels included in the accommodation?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="3. Are your properties accessible by public transportation?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="4. Can I request specific amenities or services for my stay?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="5. Can you provide information on nearby attractions or places of interest?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
       

      </div>
    </div>
  );
};
const renderCommunication  = () => {
  return (
    <div className="BookingProcess faq-comman-class" ref={section3Ref}>
      <h2>COMMUNICATION</h2>
      <div>
      <Collapsible trigger="1. How can I contact my host before and during the stay?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Once your booking is confirmed, you will receive a booking confirmation email which includes your host’s direct contact details.
            </div>
          </div> 
          </div>
      </Collapsible> 
      <Collapsible trigger="2. What should I do If can’t get in touch with my host?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="3. What should I do if I encounter issues during my stay?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="4. How can I leave a review for my stay?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
       

      </div>
    </div>
  );
};

const renderPaymentsAndRefunds = () => {
  return (
    <div className="BookingProcess faq-comman-class" ref={section4Ref}>
      <h2>PAYMENTS AND REFUNDS</h2>
      <div>
      <Collapsible trigger="1. What payment methods does Tiny Away accept?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Once your booking is confirmed, you will receive a booking confirmation email which includes your host’s direct contact details.
            </div>
          </div> 
          </div>
      </Collapsible> 
      <Collapsible trigger="2. When can I expect my refund?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
      <Collapsible trigger="3. How do I use my Tiny Away Gift Card or Klook Voucher?">
        <div className="grid grid-cols-1 xl:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 max-win-320">
          <div>
            <div className="flex items-center space-x-3">
            Sign up with Tiny Away using your email, Facebook or Google account. Choose your preferred tiny house{'>'}select the dates{'>'}enter the number of guests{'>'}click Add to Cart Choose your payment method and instantly book your stay!
           </div>
          </div> 
          </div>
      </Collapsible>
       
       

      </div>
    </div>
  );
};

  return (
    <div
    className={`nc-ListingStayMapPage relative bg-color-gry pb-14`}  >
      <Helmet title="Tiny Away | FAQ"></Helmet>
    <div className="container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none">
      {renderFaqNav()}
      {renderBookingProcess()}
      {renderCheckINAndStay()}
      {renderCommunication()}
      {renderPaymentsAndRefunds()}
   </div>
   </div>
  );
};
export default Pagecovid19policy;