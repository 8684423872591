import { Tab } from "@headlessui/react";
import StartRating from "components/StartRating/StartRating";
import React, { FC, useState, useEffect, Fragment } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import NcImage from "shared/NcImage/NcImage";
import { Route, Redirect, useHistory, Link, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { Dialog, Transition } from "@headlessui/react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutFormAddon from "../CheckOutPage/CheckoutFormAddon";
import axios from 'axios';
import { useAuth } from "hooks/useAuth"; // Adjust path if needed
type RowItem = {
  label: string;
  value: string;
  className?: string;
};
export interface PayPageProps {
  className?: string;
  clientSecret?: string;
  DefaultUserID?: string;
}
const PayPage: FC<PayPageProps> = (
  { className = "",
  }) => {
  const params = useParams();
  const history = useHistory();
  const slug = Object.values(params);
  const [user_id, setUserId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [bookingDetails, setBookingDetails] = useState([]);
  const [isdataLoaded, setIsdataLoaded] = useState(false);
  const [totalNumTickets, setTotalNumTickets] = useState(0);
  const [totalNumMeals, setTotalNumMeals] = useState(0);
  const [totalNumPets, setTotalNumPets] = useState(0);
  const [sumTicketsMealsValue, setSumTicketsMealsValue] = useState(0);
  const [sumFerryTicket, setSumFerryTicket] = useState(0);
  const [sumMeals, setSumMeals] = useState(0);
  const [sumPets, setSumPets] = useState(0);
  const [sumAddonValue, setSumAddonValue] = useState(0);
  const [details, setDetails] = useState({
    ferry_meals_ticket: 0,
    ferry_ticket: 0,
    meals: 0,
    pets: 0
  });
  const queryParams = new URLSearchParams(window.location.search);
  const step = queryParams.get("step");
  const [isclickStepfirst, setIsclickStepfirst] = useState(true);
  const [isclickStepSecond, setIsclickStepSecond] = useState(false);
  const [isclickStepThird, setIsclickStepThird] = useState(false);
  const [isBookingHasPetsAllowed, setIsBookingHasPetsAllowed] = useState(false);
  const [bookingResponse, setbookingResponse] = useState({
    totalBookings: 0,
    is_booking_has_addintionals: false,
    is_booking_has_pets_allowed: false,
    checkin_date: "", checkout_date: "",
    totalNights: 0,
    otherdates: [],
    html_addons: "",
    options: [{ label: '0', value: '' }],
    Petsoptions: [{ label: '0', value: '' }]
  });
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [orderId, setOrderId] = useState(false);
  const [paypalCurrency, setPaypalCurrency] = useState("");
  const [paypalClientid, setPaypalClientid] = useState("");
  const [stripeClientid, setStripeClientid] = useState("");
  const [isBookingFound, setIsBookingFound] = useState(false);
  const [totalTicketDaysWise, setTotalTicketDaysWise] = useState<Record<string, number>>({});
  const [mealWiseCount, setmealWiseCount] = useState<Record<string, number>>({});
  const [isstripeSingature, setIsstripeSingature] = useState(false);
  const [isPaymetContrainer, setIsPaymetContrainer] = useState(false);
  const [ispurchasedAddons, setIspurchasedAddons] = useState(false);
  const [isBuyAddonButton, SetIsBuyAddonButton] = useState(false);
  const [paymentDetais, SetPaymentDetais] = useState<any>({});
  const { user } = useAuth();
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + 'getBookings_v1?booking_code=' + slug[0] + "&user_id=" + user?.user_id)
      .then(res => {
        setBookingDetails(res.data.bookings)
        if (res.data.bookings.length > 0) {
          setIsBookingFound(true)
        }
        setIsdataLoaded(true);
        setbookingResponse(res.data);
        setIsBookingHasPetsAllowed(res.data.is_booking_has_pets_allowed);
        setStripeClientid(res.data.merchant_info.stripe_client_id);
        setPaypalClientid(res.data.merchant_info.paypal_client_id);
        setPaypalCurrency(res.data.merchant_info.default_currency);
        SetPaymentDetais(res.data.grandTotalPayments);
      })
  }, [ispurchasedAddons]);
  const rows: RowItem[] = [
    { label: 'Booking code', value: paymentDetais.bookingCode },
    { label: 'Date', value: paymentDetais.booked_date },
    { label: 'TOTAL NIGHTS PRICE', value: `${paymentDetais.currency_code} ${paymentDetais.subtotal}` },
    ...(paymentDetais.discount_amount > 0
      ? [{ label: 'Discount', value: `– ${paymentDetais.currency_code} ${paymentDetais.discount_amount}` }]
      : []),
    { label: 'Cleaning Fee', value: `${paymentDetais.currency_code} ${paymentDetais.totalCleaning}` },
    { label: 'Tiny Service Fee', value: `${paymentDetais.currency_code} ${paymentDetais.totalService}` },
    { label: 'Taxes', value: `${paymentDetais.currency_code} ${paymentDetais.totalTaxes}` },
    ...(paymentDetais.voucher_discounted_amount > 0
      ? [{ label: 'Gift Card', value: `– ${paymentDetais.currency_code} ${paymentDetais.voucher_discounted_amount}` }]
      : []),
    {
      label: 'Total',
      value: `${paymentDetais.currency_code} ${paymentDetais.grandTotal}`,
      className: 'font-semibold text-black dark:text-white border-t pt-2 mt-2',
    },
    { label: 'Payment method', value: paymentDetais.payment_method }
  ];
  const renderBookingDetails = () => {
    return (
      <div className="max-1300">
        <h2 className="text-3xl lg:text-4xl font-semibold felx-box-css mt-12">
          Your booking is confirmed!
        </h2>
        <div className="min-text-booking">
          <div className="text-part-left">
            <p>For reference, your booking ID is  {paymentDetais.bookingCode}.</p>
            <p> Mange your booking to view, cancel, or modify your booking.</p></div>
          <div>
            <a href="/reservations">
              <ButtonPrimary>MANAGE YOUR BOOKING</ButtonPrimary>
            </a>
          </div>
        </div>
        <div className="w-full flex mb-bttome">
          <div className="left-box-style-1 w-100-max">
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-5 px-0 sm:p-6 xl:p-5">
              {bookingDetails && bookingDetails.length != 0 ?
                <div className="">
                  {bookingDetails.map((bookingItem: any) => (
                    <div className="mb-4">
                      <div className="flex justify-between space-y-6 br-sm-box flex-direction-column ">
                        {/* <h3 className="text-2xl font-semibold">Booking detail's</h3> */}
                        <div className="flex flex-col sm:flex-row flex-sm-div-col-none justify-between ">

                          <div className="pt-0  sm:pb-0 sm:px-0 space-y-2">
                            <div>
                              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1 font-bold ">
                                {bookingItem.listing_name}
                              </span>
                              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1 mt-1">
                                {bookingItem.full_address}
                              </span>

                            </div>
                            {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {bookingItem.beds} beds · {bookingItem.bathrooms} baths
              </span> */}
                            <div className="mt-0 flex  date-time-sm">
                              <div className="flex gap-2 mb-1">
                                <i className="las la-calendar-alt font-size-20"></i>
                                <span className="uppercase block  text-sm text-neutral-500 dark:text-neutral-400 mb-0">
                                  {bookingItem.check_in_check_out_html}
                                </span>
                              </div>
                              <div className="flex gap-2 mb-1">
                                <i className="las la-user-friends font-size-20"></i>
                                <span className="uppercase block  text-sm text-neutral-500 dark:text-neutral-400 mb-0">
                                  {bookingItem.total_guests}
                                </span>
                              </div>
                              <div className="flex gap-2 mb-1">
                                <i className="las la-bed font-size-20"></i>
                                <span className="uppercase block  text-sm text-neutral-500 dark:text-neutral-400 mb-0">
                                  {bookingItem.beds} beds
                                </span>
                              </div>
                              <div className="flex gap-2 mb-1">
                                <i className="las la-bath font-size-20"></i>
                                <span className="uppercase block  text-sm text-neutral-500 dark:text-neutral-400 mb-0">
                                  {bookingItem.bathrooms} baths
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="flex-shrink-0 w-full sm:w-40">
                            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                              <NcImage src={bookingItem.listing_image} />
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-neutral-200 dark:border-neutral-700 pb-4 "></div>

                      </div>
                    </div>
                  ))}
                </div>

                : ""}

              <div className="p-4 bg-white dark:bg-neutral-900 rounded-xl shadow space-y-4">
                <div className="text-green-600 font-medium text-base">Your booking is paid and confirmed.</div>
                <div className="space-y-3">
                  {rows.map((item, index) => (
                    <div
                      key={index}
                      className={`flex justify-between text-sm text-neutral-600 dark:text-neutral-300 ${item.className || ''}`}
                    >
                      <span className="w-1/2 uppercase">{item.label}</span>
                      <span className="w-1/2 text-right text-neutral-900 dark:text-neutral-100">{item.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  };
  const renderskeletonLoader = () => {
    return (
      <div className="custom-loader gap-3 justify-center lg:flex-row">
        <div className="border-neutral-200 sm:border sm:rounded-2xl w-70-1 w-70-loader">
          <div className="card m-2">
            <div className="card-body">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="p-3 bg-grey skeleton"></div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <div className="p-5 bg-grey skeleton"></div>
                  <div className="p-2 bg-grey skeleton"></div>
                </div>
                <div className="col-8">
                  <div className="p-2 bg-grey mb-2 skeleton"></div>
                  <div className="p-2 bg-grey mb-2 skeleton"></div>
                  <div className="p-2 bg-grey mb-4 skeleton"></div>
                  <div className="p-2 bg-grey mb-2 skeleton"></div>
                </div>
              </div>
              <div className="row mb-3"></div>
              <div className="gap-3 mb-4 row">
                <div className="border-neutral-200 col-6 p-2.5 sm:border sm:rounded-2xl">
                  <div className="p-2 bg-grey mb-2 skeleton"></div>
                  <div className="p-2 bg-grey mb-0 skeleton"></div>
                </div>
                <div className="border-neutral-200 col-6 p-2.5 sm:border sm:rounded-2xl">
                  <div className="p-2 bg-grey mb-2 skeleton"></div>
                  <div className="p-2 bg-grey mb-0 skeleton"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="bg-grey h-6 mb-3 p-2 skeleton"></div>
                </div>
              </div>
              <div className="row"><div className="col-3 mr-40">
                <div className="bg-grey mb-3 p-1 skeleton"></div>
              </div>
                <div className="col-3 p-1">
                  <div className="bg-grey mb-3 p-1 skeleton"></div>
                </div>
              </div>
              <div className="row"><div className="col-3 mr-40">
                <div className="bg-grey mb-3 p-1 skeleton"></div>
              </div>
                <div className="col-3">
                  <div className="bg-grey mb-3 p-1 skeleton"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-3 mr-40">
                  <div className="bg-grey mb-3 p-1 skeleton"></div>
                </div>
                <div className="col-3">
                  <div className="bg-grey mb-3 p-1 skeleton"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="bg-grey h-6 mb-3 p-2 skeleton"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <Helmet title="Tiny Away | Payment Success"></Helmet>
      {isdataLoaded ?
        <div>
          <main className="container ">
            {isBookingFound ?
              renderBookingDetails()
              :
              ""
            }
          </main>
        </div>
        :
        <main className="container mt-11 mb-24 lg:mb-32 ">
          {renderskeletonLoader()}
        </main>
      }
    </div>
  );
};

export default PayPage;