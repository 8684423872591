import { Tab } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import React, { FC, Fragment,ReactNode,useEffect,useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Route, Redirect,useHistory,Link} from 'react-router-dom';
import axios from 'axios';

import {
   CardElement,
    PaymentElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
export interface CheckOutFormProps {
  className?: string;
  paymentIntent?: { // 👈️ could be undefined
    status?: string;
  };
  clientSecret?: string;
  bookingRef?: string;
  token?: string;
}

const CheckOutForm: FC<CheckOutFormProps> = ({ 
  className = "",
  paymentIntent,
  clientSecret="",
  bookingRef,
  token
}) => {
    const history                                                 = useHistory();
    const stripe                                                  = useStripe();
    const elements                                                = useElements();
    const [message, setMessage]                                   = useState("");
    const [isLoading, setIsLoading]                               = useState(false);
    const [disabled, setDisabled]                                 = useState(true);
    const [error, setError]                                       = useState('');
    const [processing, setProcessing]                             = useState(false);
    const [succeeded, setSucceeded]                               = useState(false);
    const [userDisplayName,setUserDisplayName]                    = useState("");
    const [userLocalEmail,setUserLocalEmail]                      = useState("");
      const cardStyle = {
        style: {
          base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#32325d"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }
      };
      const handleChange = async (event:any) => {
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
      };
      const handleSubmit = async (ev:any) => {
        ev.preventDefault();
        setProcessing(true);
        if (!stripe || !elements) {
             // Stripe.js has not yet loaded.
             // Make sure to disable form submission until Stripe.js has loaded.
             return;
        }
        const cardElement = elements.getElement(CardElement);
        if (cardElement!=null) {
          const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement
            }
          });
          if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
          } else {
            setError('');
            setProcessing(false);
            setSucceeded(true);
            axios.post(process.env.REACT_APP_BASE_URL + "extend_booking", {
              items: [{
                payment_details : payload,
                booking         : bookingRef,
                token_id        : token,
                payment_method  : "Stripe",
              }]
            })
              .then(function (response) {
                if (response.data.success) {
                  history.push("/extended-paydone/"+bookingRef+"/"+token);
                }
              });
            // setTimeout(() => {
            //   history.push("pay-done/"+bookingRef);
            //   history.go(0);
            // }, 2000);
          }
        }
      };
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="flex gap-2 border-r-10">
      </div>
      <div className="space-y-1 mt-2 border-input-all ">
        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      </div>
      <div className="d-flex mb-3 mt-3">
      <ButtonPrimary disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </ButtonPrimary>
      </div>
        {/* Show any error or success messages */}
        {error && (
        <div className="alert alert-danger" role="alert">  <span className="las la-exclamation-circle"></span>
         {error}</div>
      )}
      {/* Show a success message upon completion */}
        {succeeded && <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>Payment succeeded</div>}
      </form>
  );
};
export default CheckOutForm;
