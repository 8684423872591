import React, { FC, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { useAuth } from "hooks/useAuth";

export interface CheckOutFormProps {
  clientSecret?: string;
  bookingRef?: string;
  grandTotalAmount?: number;
  appliedDiscountCode?: string | null;
  appliedGiftCardCode?: string | null;
  onPaymentSuccess: (paymentData: any) => void;
  cartIds?: string;
  paymentmessage?: string;
  countryID?: string;
  setIsButtonDisabled: (disabled: boolean) => void;
  setIsButtonProccessing: (disabled: boolean) => void;
  setIsButtonSucceed: (disabled: boolean) => void;
  setErrorMessage: (message: string | null) => void;  // ✅ New Prop

}

export interface CheckOutFormRef {
  submitForm: () => void;
}

const CheckOutForm = forwardRef<CheckOutFormRef, CheckOutFormProps>(
  (
    {
      clientSecret = "",
      bookingRef,
      grandTotalAmount,
      appliedDiscountCode,
      appliedGiftCardCode,
      onPaymentSuccess,
      cartIds,
      paymentmessage,
      countryID,
      setIsButtonDisabled,
      setIsButtonProccessing,
      setIsButtonSucceed,
      setErrorMessage
    },
    ref,
  ) => {
    const stripe = useStripe();
    const elements = useElements();
    const formRef = useRef<HTMLFormElement>(null);
    const { user } = useAuth();

    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("SG");
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [disabled, setDisabled] = useState(true);

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        if (formRef.current) {
          formRef.current.requestSubmit();
        }
      },
      isDisabled: processing || disabled || succeeded,
      isButtonProccessing:processing,
      isButtonSucceed:succeeded
    }));

    const handleChange = (event: any) => {
      setDisabled(event.empty);
      ///setIsButtonSucceed(event.empty);
      setError(event.error ? event.error.message : "");
      setErrorMessage(event.error ? event.error.message : "");
      setIsButtonDisabled(event.empty || !!event.error);
    };

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
      //console.log("🛠️ Form is being submitted...");
      ev.preventDefault();
      setIsButtonProccessing(true);
      
      if (!stripe || !elements) {
        setError("Stripe has not loaded yet.");
        setErrorMessage("Stripe has not loaded yet.");
        setProcessing(false);
        return;
      }

      

      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) {
        setError("Payment card details are required.");
        setErrorMessage("Payment card details are required.");
        setProcessing(false);
        return;
      }

    // ✅ Custom Validation for Postcode, Country, and Phone
  if (!postcode) {
    setErrorMessage("Postcode is required.");
    setProcessing(false);
    return;
  }
  
  if (!country) {
    setErrorMessage("Please select a country.");
    setProcessing(false);
    return;
  }
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // ✅ Validate if all card fields are filled
    if (!cardExpiryElement || !cardCvcElement) {
      setError("Please enter your card details.");
      setErrorMessage("Please enter your card details.");
      setProcessing(false);
      return;
    }

      try {
        const validationResponse = await axios.post(
          process.env.REACT_APP_BASE_URL + "validate_strip_payment",
          {
            bookingRef,
            user_id: user?.user_id,
            amount: grandTotalAmount,
            discount_code: appliedDiscountCode,
            giftcard_code: appliedGiftCardCode,
            cart_ids: cartIds,
          },
          { headers: { "Content-Type": "application/json" } }
        );

        const validationData = validationResponse.data;
        if (!validationData.success) {
          setError(validationData.message || "Payment validation failed.");
          setErrorMessage(validationData.message || "Payment validation failed."); // ✅ Send error to parent
          setProcessing(false);
          return;
        }

        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              email: user?.user_email,
              address: {
                postal_code: postcode,
                country: country,
              },
            },
          },
        });

        if (payload.error) {
          setError(`Payment failed: ${payload.error.message}`);
          setErrorMessage(`Payment failed: ${payload.error.message}`); // ✅ Send error to parent
          setProcessing(false);
        } else if (payload.paymentIntent) {
          setSucceeded(true);
          setIsButtonSucceed(true);
          setError(null);
          setErrorMessage(null);
          setProcessing(false);

          onPaymentSuccess({
            bookingRef,
            user_id: user?.user_id,
            paymentStatus: payload.paymentIntent.status,
            transactionId: payload.paymentIntent.id,
            amount: payload.paymentIntent.amount / 100,
            currency: payload.paymentIntent.currency,
            paymentMethod: payload.paymentIntent.payment_method_types[0],
            cartIds,
            appliedDiscountCode,
            appliedGiftCardCode,
            paymentmessage,
            countryID,
            postcode,
            country,
          });
        }
      } catch (err) {
        setError("Something went wrong. Please try again.");
        setErrorMessage("Something went wrong. Please try again.");
        setProcessing(false);
      }
    };
    const cardElementOptions = {
      placeholder: 'Card number',
      style: {
        base: {
          fontSize: '16px',
          color: '#32325d',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    };
    return (
      <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
        {/* Card Fields */}
        <div className="mt-2">
          {/* <label>Card Number</label> */}
          <div className="space-y-1 mt-2 border-input-all mt-5">
            <CardNumberElement id="card-number" onChange={handleChange} options={cardElementOptions} />
          </div>
        </div>
        <div className="flex mt-2 space-x-2">
          <div className="w-1/2">
            {/* <label>Expiration Date</label> */}
            <div className="space-y-1 mt-2 border-input-all">
              <CardExpiryElement id="card-expiry" onChange={handleChange} />
            </div>
          </div>
          <div className="w-1/2">
            {/* <label>CVV</label> */}
            <div className="space-y-1 mt-2 border-input-all">
              <CardCvcElement id="card-cvc" onChange={handleChange} />
            </div>
          </div>
        </div>
        {/* Address Fields */}
        <div className="mt-2">
          {/* <label>Postcode</label> */}
          <input type="text" className="w-full border p-3 rounded" placeholder="Postcode" value={postcode} onChange={(e) => setPostcode(e.target.value)}  />
        </div>
        <div className="mt-2">
          {/* <label>Country/Region</label> */}
          <select className="w-full border p-3 rounded" value={country} onChange={(e) => setCountry(e.target.value)} >
            <option value="AF">Afghanistan</option>
            <option value="AX">Åland Islands</option>
            <option value="AL">Albania</option>
            <option value="DZ">Algeria</option>
            <option value="AD">Andorra</option>
            <option value="AO">Angola</option>
            <option value="AI">Anguilla</option>
            <option value="AG">Antigua &amp; Barbuda</option>
            <option value="AR">Argentina</option>
            <option value="AM">Armenia</option>
            <option value="AW">Aruba</option>
            <option value="AC">Ascension Island</option>
            <option value="AU">Australia</option>
            <option value="AT">Austria</option>
            <option value="AZ">Azerbaijan</option>
            <option value="BS">Bahamas</option>
            <option value="BH">Bahrain</option>
            <option value="BD">Bangladesh</option>
            <option value="BB">Barbados</option>
            <option value="BY">Belarus</option>
            <option value="BE">Belgium</option>
            <option value="BZ">Belize</option>
            <option value="BJ">Benin</option>
            <option value="BM">Bermuda</option>
            <option value="BT">Bhutan</option>
            <option value="BO">Bolivia</option>
            <option value="BA">Bosnia &amp; Herzegovina</option>
            <option value="BW">Botswana</option>
            <option value="BR">Brazil</option>
            <option value="IO">British Indian Ocean Territory</option>
            <option value="VG">British Virgin Islands</option>
            <option value="BN">Brunei</option>
            <option value="BG">Bulgaria</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="KH">Cambodia</option>
            <option value="CM">Cameroon</option>
            <option value="CA">Canada</option>
            <option value="CV">Cape Verde</option>
            <option value="BQ">Caribbean Netherlands</option>
            <option value="KY">Cayman Islands</option>
            <option value="CF">Central African Republic</option>
            <option value="TD">Chad</option>
            <option value="CL">Chile</option>
            <option value="CN">China</option>
            <option value="CX">Christmas Island</option>
            <option value="CC">Cocos (Keeling) Islands</option>
            <option value="CO">Colombia</option>
            <option value="KM">Comoros</option>
            <option value="CG">Congo - Brazzaville</option>
            <option value="CD">Congo - Kinshasa</option>
            <option value="CK">Cook Islands</option>
            <option value="CR">Costa Rica</option>
            <option value="HR">Croatia</option>
            <option value="CW">Curaçao</option>
            <option value="CY">Cyprus</option>
            <option value="CZ">Czechia</option>
            <option value="CI">Côte d’Ivoire</option>
            <option value="DK">Denmark</option>
            <option value="DJ">Djibouti</option>
            <option value="DM">Dominica</option>
            <option value="DO">Dominican Republic</option>
            <option value="EC">Ecuador</option>
            <option value="EG">Egypt</option>
            <option value="SV">El Salvador</option>
            <option value="GQ">Equatorial Guinea</option>
            <option value="ER">Eritrea</option>
            <option value="EE">Estonia</option>
            <option value="SZ">Eswatini</option>
            <option value="ET">Ethiopia</option>
            <option value="FK">Falkland Islands</option>
            <option value="FO">Faroe Islands</option>
            <option value="FJ">Fiji</option>
            <option value="FI">Finland</option>
            <option value="FR">France</option>
            <option value="GF">French Guiana</option>
            <option value="PF">French Polynesia</option>
            <option value="TF">French Southern Territories</option>
            <option value="GA">Gabon</option>
            <option value="GM">Gambia</option>
            <option value="GE">Georgia</option>
            <option value="DE">Germany</option>
            <option value="GH">Ghana</option>
            <option value="GI">Gibraltar</option>
            <option value="GR">Greece</option>
            <option value="GL">Greenland</option>
            <option value="GD">Grenada</option>
            <option value="GP">Guadeloupe</option>
            <option value="GT">Guatemala</option>
            <option value="GG">Guernsey</option>
            <option value="GN">Guinea</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="GY">Guyana</option>
            <option value="HT">Haiti</option>
            <option value="HN">Honduras</option>
            <option value="HK">Hong Kong SAR</option>
            <option value="HU">Hungary</option>
            <option value="IS">Iceland</option>
            <option value="IN">India</option>
            <option value="ID">Indonesia</option>
            <option value="IQ">Iraq</option>
            <option value="IE">Ireland</option>
            <option value="IM">Isle of Man</option>
            <option value="IL">Israel</option>
            <option value="IT">Italy</option>
            <option value="JM">Jamaica</option>
            <option value="JP">Japan</option>
            <option value="JE">Jersey</option>
            <option value="JO">Jordan</option>
            <option value="KZ">Kazakhstan</option>
            <option value="KE">Kenya</option>
            <option value="KI">Kiribati</option>
            <option value="XK">Kosovo</option>
            <option value="KW">Kuwait</option>
            <option value="KG">Kyrgyzstan</option>
            <option value="LA">Laos</option>
            <option value="LV">Latvia</option>
            <option value="LB">Lebanon</option>
            <option value="LS">Lesotho</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libya</option>
            <option value="LI">Liechtenstein</option>
            <option value="LT">Lithuania</option>
            <option value="LU">Luxembourg</option>
            <option value="MO">Macao SAR</option>
            <option value="MG">Madagascar</option>
            <option value="MW">Malawi</option>
            <option value="MY">Malaysia</option>
            <option value="MV">Maldives</option>
            <option value="ML">Mali</option>
            <option value="MT">Malta</option>
            <option value="MQ">Martinique</option>
            <option value="MR">Mauritania</option>
            <option value="MU">Mauritius</option>
            <option value="YT">Mayotte</option>
            <option value="MX">Mexico</option>
            <option value="MD">Moldova</option>
            <option value="MC">Monaco</option>
            <option value="MN">Mongolia</option>
            <option value="ME">Montenegro</option>
            <option value="MS">Montserrat</option>
            <option value="MA">Morocco</option>
            <option value="MZ">Mozambique</option>
            <option value="MM">Myanmar (Burma)</option>
            <option value="NA">Namibia</option>
            <option value="NR">Nauru</option>
            <option value="NP">Nepal</option>
            <option value="NL">Netherlands</option>
            <option value="NC">New Caledonia</option>
            <option value="NZ">New Zealand</option>
            <option value="NI">Nicaragua</option>
            <option value="NE">Niger</option>
            <option value="NG">Nigeria</option>
            <option value="NU">Niue</option>
            <option value="NF">Norfolk Island</option>
            <option value="MK">North Macedonia</option>
            <option value="NO">Norway</option>
            <option value="OM">Oman</option>
            <option value="PK">Pakistan</option>
            <option value="PS">Palestinian Territories</option>
            <option value="PA">Panama</option>
            <option value="PG">Papua New Guinea</option>
            <option value="PY">Paraguay</option>
            <option value="PE">Peru</option>
            <option value="PH">Philippines</option>
            <option value="PN">Pitcairn Islands</option>
            <option value="PL">Poland</option>
            <option value="PT">Portugal</option>
            <option value="QA">Qatar</option>
            <option value="RE">Réunion</option>
            <option value="RO">Romania</option>
            <option value="RU">Russia</option>
            <option value="RW">Rwanda</option>
            <option value="WS">Samoa</option>
            <option value="SM">San Marino</option>
            <option value="ST">São Tomé &amp; Príncipe</option>
            <option value="SA">Saudi Arabia</option>
            <option value="SN">Senegal</option>
            <option value="RS">Serbia</option>
            <option value="SC">Seychelles</option>
            <option value="SL">Sierra Leone</option>
            <option value="SG">Singapore</option>
            <option value="SX">Sint Maarten</option>
            <option value="SK">Slovakia</option>
            <option value="SI">Slovenia</option>
            <option value="SB">Solomon Islands</option>
            <option value="SO">Somalia</option>
            <option value="ZA">South Africa</option>
            <option value="GS">South Georgia &amp; South Sandwich Islands</option>
            <option value="KR">South Korea</option>
            <option value="SS">South Sudan</option>
            <option value="ES">Spain</option>
            <option value="LK">Sri Lanka</option>
            <option value="BL">St. Barthélemy</option>
            <option value="SH">St. Helena</option>
            <option value="KN">St. Kitts &amp; Nevis</option>
            <option value="LC">St. Lucia</option>
            <option value="MF">St. Martin</option>
            <option value="PM">St. Pierre &amp; Miquelon</option>
            <option value="VC">St. Vincent &amp; Grenadines</option>
            <option value="SD">Sudan</option>
            <option value="SR">Suriname</option>
            <option value="SJ">Svalbard &amp; Jan Mayen</option>
            <option value="SE">Sweden</option>
            <option value="CH">Switzerland</option>
            <option value="TW">Taiwan</option>
            <option value="TJ">Tajikistan</option>
            <option value="TZ">Tanzania</option>
            <option value="TH">Thailand</option>
            <option value="TL">Timor-Leste</option>
            <option value="TG">Togo</option>
            <option value="TK">Tokelau</option>
            <option value="TO">Tonga</option>
            <option value="TT">Trinidad &amp; Tobago</option>
            <option value="TA">Tristan da Cunha</option>
            <option value="TN">Tunisia</option>
            <option value="TR">Türkiye</option>
            <option value="TM">Turkmenistan</option>
            <option value="TC">Turks &amp; Caicos Islands</option>
            <option value="TV">Tuvalu</option>
            <option value="UM">U.S. Outlying Islands</option>
            <option value="UG">Uganda</option>
            <option value="UA">Ukraine</option>
            <option value="AE">United Arab Emirates</option>
            <option value="GB">United Kingdom</option>
            <option value="US">United States</option>
            <option value="UY">Uruguay</option>
            <option value="UZ">Uzbekistan</option>
            <option value="VU">Vanuatu</option>
            <option value="VA">Vatican City</option>
            <option value="VE">Venezuela</option>
            <option value="VN">Vietnam</option>
            <option value="WF">Wallis &amp; Futuna</option>
            <option value="EH">Western Sahara</option>
            <option value="YE">Yemen</option>
            <option value="ZM">Zambia</option>
            <option value="ZW">Zimbabwe</option>
          </select>
        </div>
        {/* Phone Number */}
        {/* {error && <div className="text-red-600 mt-2">{error}</div>} */}
        {/* {succeeded && <div className="text-green-600 mt-2">Payment succeeded!</div>} */}
      </form>
    );
  }
);

export default CheckOutForm;
