import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/2-750x500.jpg";
import NcImage from "shared/NcImage/NcImage";
import Input from "shared/Input/Input";
import SuccessMessage from "components/SuccessMessage/SuccessMessage";
import axios from "axios";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const [message, setMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const onSubmit = async (data: any) => {
    try {
      const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/subscribe_newsletters`,
          data,
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.data.status === 200) {
          //setMessage("✅ Form submitted successfully!");
          setShowSuccess(true);
          reset();
        } else {
          setError("email", {
            type: "manual",
            message: response.data.response,
          });
        }
    } catch (error) {
      setError("email", {
            type: "manual",
            message: "Failed to subscribe. Please try again.",
          });
    }
  };

  return (
    <div
      className={`flex-full nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ng-width-940 ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>

      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="text-5xl md:text-5xl font-semibold">Join Our Newsletter</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Receive exclusive email offers and promotions from Tiny Away. We know it is hard to book your preferred weekend dates due to overwhelming demand. Be on our mailing list and be informed on our newest tiny houses and what’s coming up soon!
        </span>
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          More importantly, we always have exclusive offers and information for our Tiny Away family. Be the first to be informed.
        </span>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-7 relative max-w-sm">
          <Input
            type="text"
            placeholder="Enter your email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email format",
              },
              maxLength: {
                value: 50,
                message: "Email must not exceed 50 characters",
              }
            })}
          />
          <ButtonCircle
            type="submit"
            disabled={isSubmitting}
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
          {showSuccess && (
            <SuccessMessage message="You have subscribed successfully" onClose={() => setShowSuccess(false)} />
          )}
        </form>
          {errors.email && (
            <p className="text-red-500 text-sm mt-2">{errors.email.message}</p>
          )}
           
          {message && (
            <p className="text-green-600 text-sm mt-2">{message}</p>
          )}
      </div>
    </div>
  );
};

export default SectionSubscribe2;
