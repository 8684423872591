// components/BookingCardSkeleton.tsx
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BookingCardSkeleton = () => {
  return (
    <div className="flex justify-between p-6 border rounded-lg shadow-sm mb-6 bg-white">
      <div className="flex flex-col gap-3 w-3/4">
        <Skeleton width={200} height={20} />
        <Skeleton width={300} height={16} />

        <div className="flex gap-4 mt-2">
          <Skeleton width={100} height={16} />
          <Skeleton width={80} height={16} />
          <Skeleton width={80} height={16} />
        </div>

        <div className="mt-4">
          <Skeleton width={120} height={16} />
          <Skeleton width={200} height={16} />
        </div>
      </div>

      <div className="flex flex-col items-end gap-2">
        <Skeleton width={160} height={100} />
        <Skeleton width={120} height={36} borderRadius={999} />
      </div>
    </div>
  );
};

export default BookingCardSkeleton;
