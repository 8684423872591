import React, { useState,useEffect } from 'react';
import { FaCreditCard, FaPaypal,FaCcMastercard } from 'react-icons/fa';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

interface PaymentDropdownProps {
    amount: number;
    onSelectPayment: (paymentMethod: string) => void;  // ✅ New prop to send data to parent
    allowedMethods?: string[]; // ✅ NEW: Optional prop like ["STRIPE", "PAYPAL"]
  }

const PaymentDropdown: React.FC<PaymentDropdownProps> = ({amount,onSelectPayment,allowedMethods }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    amount === 0 ? 'GIFT CARD' : 'CREDTI OR DEBIT CARD'
  );
  const CreditCardIcon = FaCreditCard as unknown as React.FC;
  const PayPalIcon = FaPaypal as unknown as React.FC;
  const allOptions  = [
    { label: 'CREDTI OR DEBIT CARD', value:"STRIPE", icon: <CreditCardIcon /> },
    { label: 'PAYPAL', value:"PAYPAL", icon: <PayPalIcon /> },
    { label: 'GIFT CARD', value: "GIFTCARD", icon: <i className='la la-gift'></i> },
  ];

  const options = allOptions.filter(opt => {
    if (amount === 0) return opt.value === "GIFTCARD";
    if (allowedMethods && allowedMethods.length > 0) {
      return allowedMethods.includes(opt.value);
    }
    return opt.value !== "GIFTCARD";
  });

  useEffect(() => {
    if (options.length === 0) return;
  
    const current = options.find(opt => opt.label === selectedOption);
  
    if (!current) {
      const newDefault = options[0];
      setSelectedOption(newDefault.label);
      onSelectPayment(newDefault.value);
    } else {
      // ✅ Ensure parent always knows the current selected value
      onSelectPayment(current.value);
    }
  }, [amount, allowedMethods]);
  

  const handleSelect = (option: any) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    onSelectPayment(option.value);  // ✅ Send value to parent
  };
  return (
    <div className="relative w-full">
      <div
        className="flex items-center justify-between w-full p-3 border rounded-lg cursor-pointer bg-white shadow-sm"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-2">
          {options.find((o) => o.label === selectedOption)?.icon}
          <span>{selectedOption}</span>
        </div>
        {isOpen ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
        
      </div>
      {isOpen && (
        <ul className="absolute left-0 w-full mt-2 border rounded-lg bg-white shadow-lg overflow-hidden  z-50">
          {options.map((option) => (
            <li
              key={option.label}
              className="flex items-center gap-2 p-3 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelect(option)}
            >
              {option.icon}
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PaymentDropdown;
