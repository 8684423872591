import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyReviewType } from "data/types";
import ncNanoId from "utils/ncNanoId";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import CardCategory7 from "components/CardCategory7/CardCategory7";


export interface SectionAibnbReviewsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyReviewType[];
  categoryCardType?: "card3" | "card4" | "card5" | "card6" | "card7";
  itemPerRow?: 4 | 5 | 1;
  sliderStyle?: "style1" | "style2";
  hasNextPrev?: boolean;
  isCenter?: boolean;
}
const SectionAibnbReviews: FC<SectionAibnbReviewsProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "",
  categories = [],
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
  isCenter = false,
  hasNextPrev = false,
}) => {
  const UNIQUE_CLASS = "glide_" + ncNanoId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 32,
        bound: true,
        autoplay: categoryCardType === "card6" ? 3000 : false, // Enable autoplay only for "card3" and "card5"
        // animationDuration: 1000, // Smooth transition
        // hoverpause: true, // Pause on hover
        breakpoints: {
          1280: {
            perView: itemPerRow - 1,
          },
          1024: {
            gap: 20,
            perView: itemPerRow - 1,
          },
          768: {
            gap: 20,
            perView: itemPerRow - 2,
          },
          640: {
            gap: 20,
            perView: itemPerRow - 3,
          },
          500: {
            gap: 20,
            perView: 1.3,
          },
        },
      }).mount();
    }
  }, [UNIQUE_CLASS, categories]);
  const renderCard = (item: TaxonomyReviewType, index: number) => {
    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={item} />;
      case "card4":
        return <CardCategory4 taxonomy={item} />;
      case "card5":
        return <CardCategory5 taxonomy={item} />;
      case "card6":
        return <CardCategory6 taxonomy={item} />;
      case "card7":
        return <CardCategory7 taxonomy={item} />;
      default:
        return <CardCategory3 taxonomy={item} />;
    }
  };

  return (
    <div className={`nc-SectionAibnbReviews ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        {categoryCardType != 'card7' ?
          <Heading
            desc={subHeading}
            hasNextPrev={sliderStyle === "style1"}
            isCenter={sliderStyle === "style2"}
          >
            {heading}
          </Heading>
          : ""
        }

        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">

            {categories.map((item, index) => (
              <>
                {categoryCardType == 'card7' ?
                  <li key={index} className={`glide__slide ${itemClassName}`}>
                    <div className="class-SectionAibnbReviews">
                      <div className="">
                        <img src={item.download_path} />
                      </div>
                      <div className="SectionAibnbReviews-text">
                        <h2>WHAT OUR GUESTS SAYING...</h2>
                        {renderCard(item, index)}
                      </div>
                    </div>
                  </li>
                  :
                  <li key={index} className={`glide__slide ${itemClassName}`}>
                    {renderCard(item, index)}
                  </li>
                }
              </>
            ))}
          </ul>
          {categoryCardType == 'card7' ?
            <div className="mt-0 flex justify-center sm:ml-2 sm:mt-0 flex-shrink-0 icon-top-mid">
              <NextPrev className="justify-center mt-0" onClickNext={() => { }} onClickPrev={() => { }} />
            </div>
            : ""
          }
        </div>
        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionAibnbReviews;
