import React, { FC,useEffect,useState } from "react";
import { useParams } from 'react-router-dom';
import { ArrowRightIcon } from "@heroicons/react/outline";
//mport LocationMarker from "components/AnyReactComponent/LocationMarker";
import AnyReactThingsComponent from "components/AnyReactComponent/AnyReactThingsComponent";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import GallerySlider from "components/GallerySlider/GallerySlider";
import axios from 'axios'; 
import { Helmet } from "react-helmet";

export interface TravelinpirationAreaProps {
  className?: string;
  ratioClass?: string;
}


const galleryImgs:string[]=[
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg",
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg",
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg"

];
const includes_demo = [
  { name: "Set Menu Lunch on boat" },
  { name: "Express Bus From Hanoi To Halong and Return" },
  { name: "Mineral Water On Express Bus" },
  { name: "Kayak or Bamboo Boat. Life Jacket." },
  { name: "Halong Bay Entrance Ticket" },
];
const TravelinpirationArea: FC<TravelinpirationAreaProps> = ({
  className = "",
  ratioClass = "aspect-w-3 aspect-h-2",
}) => {
  const params                                      = useParams();
  // yuo can find all params from here
  const slug                                        = Object.values(params);
  const [isOpen, setIsOpen]                         = useState(false);
  const [openFocusIndex, setOpenFocusIndex]         = useState(0);
  const [categoryName, setCategoryName]             = useState([]);
  const [infuencername, setInfuencername]           = useState([]);
  const [PHOTOS, setPHOTOS]                         = useState([]);
  const [descriptions, setDescriptions]             = useState("");
  const [address, setAddress]                       = useState("");
  const [thingsWeDid, setThingsWeDid]               = useState([]);
  const [metatitle, SetMetatitle]                   = useState([]);
  const [metadata, SetMetaData]                     = useState([]);
  const [imagePng, setImagePng]                     = useState("");
  //const [thingsHref, setThingsHref]                 = useState("");

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'GetTravelInspirationsDetails?id='+slug)
 	 .then(res => {
          setCategoryName(res.data.category_name);
          setPHOTOS(res.data.galleryImgs);
          setDescriptions(res.data.publicDescription);
          setAddress(res.data.address);
          setThingsWeDid(res.data.thingswedid);
          SetMetatitle(res.data.meta_title);
          SetMetaData(res.data.meta_data);
          setImagePng(res.data.featured_img);
    });   
  }, []);
  

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const handleCloseModal = () => setIsOpen(false);
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6  Things_to_know">
       <h2 className="text-2xl font-semibold text-center text-lg">
          {categoryName}
        </h2>
        <div className="flex items-center flex-direction-column">
        </div>
        <div dangerouslySetInnerHTML={ {__html: descriptions} } />
      </div>
    );
  };
 
  return (
    <div
      className={`nc-ListingExperiencesDetailPage  ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
      {/* SINGLE HEADER */}
      <Helmet title={metatitle} meta={metadata}></Helmet>
      <>
        <header className="py-16 lg:py-0 space-y-16 lg:space-y-28 pt-0">
        <div className="relative ">
          <img className="w-full" src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg" alt="hero" />
        </div>
        </header>
        {/* MODAL PHOTOS */}
      </>
      {/* MAIn */}
      <main className="container flex flex-wrap gap-4 mt-4 mb-4">
       
  
        <div className="banner-text-page flex flex-wrap gap-4  w-full">
       <div className="width-3">
        <img className="w-full" src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        <div className="width-3">
        <img className="w-full" src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        <div className="width-3">
        <img className="w-full" src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        <div className="width-3">
        <img className="w-full" src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        <div className="width-3">
        <img className="w-full" src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        <div className="width-3">
        <img className="w-full" src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        <div className="width-3">
        <img className="w-full" src="https://pictures.tinyaway.com/filters:format(jpg)/417x480/Listings/Images/2022/10/225/ayxqzjvhleghfignkuaq1666860842049.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        <div className="width-3">
        <img className="w-full" src="https://pictures.tinyaway.com/filters:format(jpg)/417x480/Listings/Images/2022/10/225/vrwiuzrgfqsszykvgfbq1666860846734.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        <div className="width-3">
        <img className="w-full" src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg" alt="hero" />
        <div className="h-page-list"><h2>Picton</h2><p>VIC</p></div>
        </div>
        
        </div>
      </main>
    </div>
  );
};

export default TravelinpirationArea;
