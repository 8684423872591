import React, { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import axios from 'axios'; 
import { $CombinedState } from "@reduxjs/toolkit";

export interface LocationInputProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  name?:string;
  location?:string;
  defaultPlaceValue?:string;
}

const LocationInput: FC<LocationInputProps> = ({
  defaultValue,
  autoFocus = false,
  onChange,
  onInputDone,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  name="location",
  defaultPlaceValue
}) => {
  const containerRef                                = useRef<HTMLDivElement>(null);
  const inputRef                                    = useRef<HTMLInputElement>(null);
  const placeInputRef = useRef<HTMLInputElement>(null);
  const [value, setValue]                           = useState(defaultValue);
  const [placeValue, setPlaceValue]                 = useState(defaultPlaceValue);
  const [showPopover, setShowPopover]               = useState(autoFocus);
  const [autocompletevaues, setautocompletevaues]   = useState([]); 
  const [isGrampians, setIsGrampians]               = useState(false);
  

  useEffect(() => {
    setValue(defaultValue);
    //setPlaceValue(defaultPlaceValue);
    //console.log("AMIT"+defaultValue+"====="+defaultPlaceValue);
    axios.get(process.env.REACT_APP_BASE_URL+'/Autocomplete?autocomplete_text='+defaultValue)
 	 .then(res => {
          setautocompletevaues(res.data.autocomplete_terms); 
          //setIsGrampians(res.data.is_grampians);
          //console.log(autocompletevaues);
          //setPlaceValue(defaultPlaceValue);
          
    }); 
  }, [defaultValue]);//autocompletevaues

  useEffect(() => {
    if (defaultPlaceValue) {
      setPlaceValue(defaultPlaceValue);
    }
  }, [defaultPlaceValue]); 

  useEffect(() => {
    setShowPopover(autoFocus);
    //console.log(autoFocus);
    }, []);
  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);
  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    if(isGrampians==true){
        setValue('Escape @ Grampians Edge');
      }
  }, [isGrampians]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string,place_id:string) => {
    setValue(item);
    setPlaceValue(place_id);
    onInputDone && onInputDone(item);
    setShowPopover(false);
  };

  const handleRedirectLocation = (item: string) => {
    window.open(item);
  }

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Recent searches
        </h3>
        <div className="mt-2">
          {[/*
          "Ha Noi, Viet Nam",
          "San Diego, CA",
          "Humboldt Park, Chicago, IL",
          "Bangor, Northern Ireland",
    */].map((item) => (
            <span
              onClick={() => handleSelectLocation(item,"")}
              key={item}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 sm:h-6 w-4 sm:w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {autocompletevaues.map((item, index) => (
          item['suggestion_type']=='LOCATION'?
          <span onClick={() => handleSelectLocation(item['location']['query'],item['location']['place_id'])} key={index} className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-2 sm:py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer">
            <span className="block text-neutral-400 bg-color-search-icon">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"  />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item['location']['query']}
            </span>
          </span>
          :<a onClick={() => handleRedirectLocation(item['pdp_details']['redirect_url'])} className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-2 sm:py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer">
          <span className="block text-neutral-400 img-search sss">
              <img src={item['pdp_details']['thumbnail_image_url']}></img>
          </span>
          <span className="block font-medium text-neutral-700 dark:text-neutral-200">
            {item['pdp_details']['display_name']}
          </span>
        </a>
        ))}
      </>
    );
  };
  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none border-r text-left  ${
          showPopover ? "shadow-2xl rounded-full dark:bg-neutral-800" : ""
        }`}
      >
      
        <div className="flex-grow flex-shrink-phone">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
            name="location_search"
            autoComplete="off"
            readOnly={isGrampians}
          />
          <input type={"hidden"} 
                ref={placeInputRef} 
                onChange={(e) => setPlaceValue(e.currentTarget.value)} 
                value={placeValue} 
                name="place_id" 
                id="place_id" 
                autoComplete="off" />
           
          {value && showPopover && (
            <ClearDataButton onClick={() => {
              setValue("");
              setPlaceValue("");
              if (placeInputRef.current) {
                placeInputRef.current.value = ""; // ✅ Force reset hidden input field
              }
            }} />
          )}
         
        </div>
        <i className="ml-3 las la-arrow-right text-xl"></i>

      </div>
      
      {showPopover && !isGrampians && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value ? renderSearchValue() : renderRecentSearches()}
       </div>
      )}
    </div>
  );
};

export default LocationInput;
