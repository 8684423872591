import Label from "components/Label/Label";
import React, { FC, Fragment, useState, useEffect, useRef, useContext } from "react";
import { Route, Redirect, useHistory, Link, useParams } from 'react-router-dom';
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ReCAPTCHA from "react-google-recaptcha";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, PinterestShareButton, LinkedinShareButton, EmailShareButton, FacebookMessengerShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, PinterestIcon, LinkedinIcon, EmailIcon } from "react-share";
import SuccessMessage from "components/SuccessMessage/SuccessMessage";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed


export interface AccountPageProps {
  className?: string;
  DefaultUser?: object;
  autoFocus?: boolean;
}


const AccountPageLeftMenu = () => {
  return (
    <></>
  )
}

const AccountPage: FC<AccountPageProps> = ({
  className = "",
  autoFocus = false
}) => {


  const history = useHistory();
  const [user_id, setUserId] = useState<any>({});
  const [users, setUser] = useState<any>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null; // Load from localStorage if available
  });
  const [imageURLs, setImageURLs] = useState("");
  const [defaultPic, setDefaultPic] = useState("");
  const [isdataLoaded, setIsdataLoaded] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    dob: "",
    gender: "",
    address: "",
    phone: "",
    aboutus: "",
    google_captcha: "",
    display_name: "",
    country: "",
    state: "",
    city: ""
  });
  const [editingField, setEditingField] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    dob: "",
    gender: "",
    address: "",
    phone: "",
    aboutus: "",
    google_captcha: "",
    display_name: "",
    country: "",
    state: "",
    city: ""
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { user, logout, updateUser } = useAuth();
  const [showSuccessNochange, setShowSuccessNochange] = useState(false);
  const [showSuccessName, setShowSuccessName] = useState(false);
  const [showSuccessDob, setShowSuccessDob] = useState(false);
  const [showSuccesGender, setShowSuccesGender] = useState(false);
  const [showSuccesAddress, setShowSuccesAddress] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>({});
  const [selectedState, setSelectedState] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState([]);

  const [optionList, setOptionList] = useState([{ value: "id", label: "Red1" }]);
  const [optionStateList, setOptionStateList] = useState([{ value: "id", label: "Red1" }]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedStateOption, setSelectedStateOption] = useState([{ value: "id", label: "" }]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + 'getCountryList')
      .then(res => {
        setOptionList(res.data.response);
      })
  }, [selectedOption]);
  console.log("selected" + selectedCountry);
  useEffect(() => {
    if (formData.country) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}getStateList?country_id=${formData.country}`)
        .then(res => {
          setOptionStateList(res.data.response);
          setSelectedState(""); // Reset selected state when country changes
        })
        .catch(err => console.error("Error fetching states:", err));
    }
  }, [formData.country]); // Now correctly depends on selectedCountry
  // useEffect(() => {
  //   const userStr = localStorage.getItem("user");
  //   if (userStr) {
  //     try {
  //       const userObj = JSON.parse(userStr);
  //       setUser(userObj);
  //       setUserId(user.user_id);
  //       if (user.user_id == "") {
  //         history.push("/login");
  //       }
  //     } catch (error) {
  //       console.error("Error parsing user:", error);
  //     }
  //   }
  // }, [user.user_id]);




  const validateForm = (field: keyof typeof formData, value?: string) => {
    let newErrors: Partial<Record<keyof typeof formData, string>> = {};
    //console.log(`Validating ${field}:`, value); // ✅ Debugging

    if (!value || !value.trim()) {
      newErrors[field] = `${field.replace("_", " ")} is required`;
    } else {
      if (field === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        newErrors[field] = "Invalid email format";
      }
      if (field === "phone" && !/^\d{10,15}$/.test(value)) {
        newErrors[field] = "Invalid phone number format";
      }
      if (field === "dob") {
        const dobParts = value.split("-");
        // Ensure the input has exactly 3 parts (YYYY-MM-DD)
        if (dobParts.length !== 3) {
          newErrors[field] = "Invalid date format (YYYY-MM-DD)";
        } else {
          let [year, month, day] = dobParts;

          // Pad month & day with leading zeros if necessary
          month = month.padStart(2, "0");
          day = day.padStart(2, "0");

          // Construct the normalized date
          const normalizedDOB = `${year}-${month}-${day}`;
          //console.log("Normalized DOB:", normalizedDOB); // Debugging

          // Validate using regex
          if (!/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(normalizedDOB)) {
            newErrors[field] = "Invalid date format (YYYY-MM-DD)";
          }
        }
      }
    }

    //console.log("Errors after validation:", newErrors); // ✅ Debugging

    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
    return Object.keys(newErrors).length === 0;
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value, // Updates the correct field dynamically
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "" // Clears the error message
    }));
    validateForm(name as keyof typeof formData, value); // Validate on change
  };

  // Save the changes
  const handleSave = () => {
    if (!editingField) return;

    const key = editingField as keyof typeof formData;
    const value = formData[key]?.trim() ?? "";

    let isValid = true;
    if (editingField === "first_name") {
      isValid = validateForm("first_name", formData.first_name)
        && validateForm("last_name", formData.last_name);
    } else if (editingField === "address") {
      isValid = validateForm("country", formData.country) && validateForm("state", formData.state);
    } else if (editingField === "dob") {
      isValid = validateForm("dob", formData.dob); // ✅ Ensure DOB validation is executed
    } else if (editingField === "gender") {
      isValid = validateForm("gender", formData.dob); // ✅ Ensure DOB validation is executed
    } else {
      isValid = validateForm(key, value);
    }
    if (!isValid) return;

    // Find only the updated fields
    const updatedFields: Partial<typeof formData> = {};
    Object.keys(formData).forEach((field) => {
      if (formData[field as keyof typeof formData] !== details[field as keyof typeof details]) {
        updatedFields[field as keyof typeof formData] = formData[field as keyof typeof formData];
      }
    });

    if (Object.keys(updatedFields).length === 0) {
      setShowSuccessNochange(true);
      setEditingField(null);
      return;
    }
    // Send only updated fields
    axios.post(process.env.REACT_APP_BASE_URL + 'update_user_info?user_id=' + users.user_id, updatedFields)
      .then(res => {
        //console.log(updatedFields);
        setDetails({ ...details, ...updatedFields }); // Update local state
        //setDetails((prev) => ({ ...prev, display_name: newFirstName }));
        setEditingField(null);
        if (editingField === "first_name") {
          setShowSuccessName(true);
          updateUser("display_name", "");
        } else if (editingField === "dob") {
          setShowSuccessDob(true);
        } else if (editingField === "gender") {
          setShowSuccesGender(true);
        } else if (editingField === "address") {
          setShowSuccesAddress(true);
        }
        updateUser(res.data.user);
        //setShowSuccess(true);
        //
      })
      .catch(error => {
        //console.error("Error updating user info", error);
      });
  };
  useEffect(() => {
    setDefaultPic("<div></div>")
    axios.get(process.env.REACT_APP_BASE_URL + 'get_user_info?user_id=' + user?.user_id)
      .then(res => {
        //console.log(res.data);
        setDetails({
          first_name: res.data.user_details.first_name,
          last_name: res.data.user_details.last_name,
          email: res.data.user_details.user_email,
          username: res.data.user_details.user_name,
          dob: res.data.user_details.dob,
          gender: res.data.user_details.gender,
          address: res.data.user_details.address,
          phone: res.data.user_details.phone_number,
          aboutus: res.data.user_details.aboutus,
          google_captcha: "",
          display_name: res.data.user_details.display_name,
          country: res.data.user_details.country,
          state: res.data.user_details.state_id,
          city: res.data.user_details.city,
        });
        setDefaultPic(res.data.user_details.user_pic);
        setIsdataLoaded(true);
        setFormData({
          first_name: res.data.user_details.first_name,
          last_name: res.data.user_details.last_name,
          email: res.data.user_details.user_email,
          username: res.data.user_details.user_name,
          dob: res.data.user_details.dob,
          gender: res.data.user_details.gender,
          address: res.data.user_details.address,
          phone: res.data.user_details.phone_number,
          aboutus: res.data.user_details.aboutus,
          google_captcha: "",
          display_name: res.data.user_details.display_name,
          country: res.data.user_details.country,
          state: res.data.user_details.state_id,
          city: res.data.user_details.city,
        });
      })
  }, [user?.user_id]);
  const handleEdit = (field: string) => {
    setEditingField(field);
    //setOriginalFormData(formData); // Store the original value
  };

  const handleCancel = () => {
    setFormData(details); // Restore the original value
    setEditingField(null);
    setErrors({}); // Clear errors
  };
  const generateOptions = (start: number, end: number) => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i).map(num => (
      <option key={num} value={num}>{num}</option>
    ));
  };
  //console.log(formData.state + "statesss");


  useEffect(() => {
    if (selectedCountry) {
      //setStates(statesByCountry[selectedCountry] || []);
      setSelectedState("");
    }
  }, [selectedCountry]);

  const AccountPageMidSection = () => {
    return (
      <div className="right-user-details">
        <h4>Hi {user?.display_name}</h4>
        <div className="mid-list-menu">
          <ul>
            <li><a className="uppercase" href="javascript:void(0)"><i className="las la-photo-video"></i>{user
              ? `${user?.country_name || ""}${user?.state_name ? ", " + user.state_name : ""}${user?.city ? ", " + user.city : ""}`.trim() || "Not specified"
              : "Not specified"}</a></li>

            {(user?.email_verified ?? false) && (
              <li><a href=""><i className="las la-photo-video"></i> EMAIL VERIFIED</a></li>
            )}
            {(user?.mobile_verified ?? false) && (
              <li><a href=""><i className="las la-photo-video"></i> PHONE VERIFIED</a></li>
            )}
          </ul>
        </div>

        <div className="mt-5 mb-5 from-edit-click">
          <div className="line"></div>
          <label></label>
          <div className="flex justify-between"><span>I'm Guest :) </span></div>
        </div>

        <div className="mt-5 mb-5 from-edit-click">
          <h4>PERSONAL INFO</h4>
          <label className="sm-text">Your name </label>
          <div className="flex justify-between">
            {editingField === "first_name" ? (
              <form className="flex flex-direction-column gap-2 w-100 gap-3" name="legalName" onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                <div className="felx felx-grid gap-4">
                  <div>
                    <Input
                      type="text"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      className="input-field"
                      name="first_name"
                      id="first_name"
                      placeholder="First name"
                    />
                    {errors.first_name && <p className="text-red-500">{errors.first_name}</p>}
                  </div>

                  <div>

                    <Input
                      type="text"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      className="input-field"
                      name="last_name"
                      id="last_name"
                      placeholder="Last name"
                    />
                    {errors.last_name && <p className="text-red-500">{errors.last_name}</p>}
                  </div>
                </div>
                <ButtonPrimary className="w-20 " type="submit">Save</ButtonPrimary>
              </form>
            ) : (
              <span className="bold-text">{user?.display_name}</span>
            )}
            {editingField === "first_name" ? (
              <button onClick={handleCancel} className="btn-save">Cancel</button>
            ) : (
              <button onClick={() => handleEdit("first_name")} disabled={editingField !== null} className="btn-edit">Edit</button>
            )}
          </div>

          <div className="line-mid mt-5 mb-5"></div>
        </div>
        {showSuccessNochange && (
          <SuccessMessage message="you have not updated anything" onClose={() => setShowSuccessNochange(false)} />
        )}
        {showSuccesGender && (
          <SuccessMessage message="Gender has been updated" onClose={() => setShowSuccesGender(false)} />
        )}
        {showSuccessDob && (
          <SuccessMessage message="Date of birth updated" onClose={() => setShowSuccessDob(false)} />
        )}
        {showSuccessName && (
          <SuccessMessage message="Legal name updated" onClose={() => setShowSuccessName(false)} />
        )}
        {showSuccesAddress && (
          <SuccessMessage message="Address has been updated" onClose={() => setShowSuccesAddress(false)} />
        )}
        <div className="mt-5 mb-5 from-edit-click">
          <label className="sm-text">Date of birth</label>
          <div className="flex justify-between">
            {editingField === "dob" ? (
              <form className="flex flex-direction-column gap-2 w-100 gap-3" name="dob" onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                <div className="flex flex-grid gap-4">
                  <div>
                    <Select name="day" value={parseInt(formData.dob?.split('-')[2], 10) || ""}
                      onChange={(e) => {
                        const updatedDOBday = `${formData.dob?.split('-')[0] || "2000"}-${formData.dob?.split('-')[1] || "01"}-${e.target.value}`;
                        setFormData({ ...formData, dob: updatedDOBday });
                        setErrors((prevErrors) => ({ ...prevErrors, dob: "" }));
                      }
                      }>
                      <option value="">Day</option>
                      {generateOptions(1, 31)}
                    </Select>
                  </div>
                  <div>
                    <Select name="month" value={parseInt(formData.dob?.split('-')[1], 10) || ""}
                      onChange={(e) => {
                        const updatedDOBMonth = `${formData.dob?.split('-')[0] || "2000"}-${e.target.value}-${formData.dob?.split('-')[2] || "01"}`;
                        setFormData({ ...formData, dob: updatedDOBMonth });
                        setErrors((prevErrors) => ({ ...prevErrors, dob: "" }));
                      }}>
                      <option value="">Month</option>
                      {generateOptions(1, 12)}
                    </Select>
                  </div>
                  <div>
                    <Select name="year" value={formData.dob?.split('-')[0] || ""}
                      onChange={(e) => {
                        const updatedDOBYear = `${e.target.value}-${formData.dob?.split('-')[1] || "01"}-${formData.dob?.split('-')[2] || "01"}`;
                        setFormData({ ...formData, dob: updatedDOBYear });
                        setErrors((prevErrors) => ({ ...prevErrors, dob: "" }));
                      }}>
                      <option value="">Year</option>
                      {generateOptions(1900, new Date().getFullYear())}
                    </Select>
                  </div>

                </div>
                {errors.dob && <p className="text-red-500">{errors.dob}</p>}
                <ButtonPrimary className="w-20 " type="submit">Save</ButtonPrimary>
              </form>
            ) : (
              <span className="bold-text">{user?.dob || "Not specified"}</span>
            )}
            {editingField === "dob" ? (
              <button onClick={handleCancel} className="btn-save">Cancel</button>
            ) : (
              <button onClick={() => handleEdit("dob")} disabled={editingField !== null} className="btn-edit">Edit</button>
            )}
          </div>

          <div className="line-mid mt-5 mb-5"></div>
        </div>

        <div className="mt-5 mb-5 from-edit-click">
          <label className="sm-text">Gender</label>
          <div className="flex justify-between">
            {editingField === "gender" ? (
              <form
                className="flex flex-direction-column gap-2 w-100 gap-3"
                name="gender"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
              >
                <div className="flex flex-grid gap-4">
                  <Select
                    name="gender"
                    value={formData.gender}
                    onChange={(e) =>
                      setFormData({ ...formData, gender: e.target.value })
                    }
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Select>
                  {errors.gender && <p className="text-red-500">{errors.gender}</p>}
                </div>
                <ButtonPrimary className="w-20 " type="submit">
                  Save
                </ButtonPrimary>
              </form>
            ) : (
              <span className="bold-text">{user?.gender || "Not specified"}</span>
            )}
            {editingField === "gender" ? (
              <button onClick={handleCancel} className="btn-save">
                Cancel
              </button>
            ) : (
              <button
                onClick={() => handleEdit("gender")}
                disabled={editingField !== null}
                className="btn-edit"
              >
                Edit
              </button>
            )}
          </div>
          <div className="line-mid mt-5 mb-5"></div>
        </div>

        <div className="mt-5 mb-5 from-edit-click">
          <label className="sm-text">Location</label>
          <div className="flex justify-between">
            {editingField === "address" ? (
              <form
                className="flex flex-direction-column gap-2 w-100 gap-3"
                name="address"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
              >
                <div className="flex  gap-4">
                  {console.log(formData.country)}
                  <div>
                    <label>Country</label>
                    <Select
                      name="country"
                      id="country"
                      value={formData.country || ""} // Ensure it's not undefined
                      onChange={(e) => {
                        const countryValue = e.target.value;
                        setSelectedCountry(countryValue);
                        setFormData({ ...formData, country: countryValue });
                      }}
                    >
                      <option value="">Select Country</option>
                      {optionList.map((country) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </Select>
                  </div>

                  {/* State Dropdown */}
                  <div>
                    <label>State</label>
                    <Select
                      name="state"
                      id="state"
                      value={formData.state}
                      onChange={(e) => {
                        const stateValue = e.target.value;
                        setFormData({ ...formData, state: stateValue });
                      }}
                      disabled={!selectedCountry}
                    >
                      <option value="">Select State</option>
                      {optionStateList.map((state, index) => (
                        <option key={index} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </Select>
                  </div>

                  {/* City Input */}
                  <div>
                    <label>City</label>
                    <Input
                      name="city"
                      id="city"
                      type="text"
                      value={formData.city}
                      onChange={handleInputChange}
                      placeholder="Enter City"
                    />
                  </div>
                </div>

                <ButtonPrimary className="w-20 mt-3" type="submit">
                  Save
                </ButtonPrimary>
              </form>
            ) : (
              <span className="bold-text">
                {user
                  ? `${user?.country_name || ""}${user?.state_name ? ", " + user.state_name : ""}${user?.city ? ", " + user.city : ""}`.trim() || "Not specified"
                  : "Not specified"}
              </span>
            )}

            {/* Edit & Cancel Buttons */}
            {editingField === "address" ? (
              <button onClick={handleCancel} className="btn-save">
                Cancel
              </button>
            ) : (
              <button
                onClick={() => handleEdit("address")}
                disabled={editingField !== null}
                className="btn-edit"
              >
                Edit
              </button>
            )}
          </div>
          <div className="line-mid mt-5 mb-5"></div>
        </div>

        <div className="mt-5 mb-5 from-edit-click">
          <h4>LOGIN METHODS</h4>
          <h5>Email & phone number</h5>
          <div className="socal-list-nav gap-5">
            <div className="list-socal">
              <div>
                <div className="flex gap-0 flex-direction-column">
                  <div className="flex gap-2 items-center"><span className="btn-sm-blu-light">
                    <i className="las la-envelope"></i> 
                    </span> 
                    <span>Email</span> 
                    
                    </div>
                  <div><a href="" className="bold-black-an">{user?.user_email || "Not specified"}</a>
                  </div>
                </div>
                <div>
                  <a href="javascript:void(0);">{user?.email_verified}</a>
                  <span className="btn-ch-sm"><i className={(user?.email_verified ?? false) ? "las la-check" : "las la-times"}></i></span>
                </div>
              </div>
            </div>

            <div className="list-socal">
              <div>
                <div className="flex gap-0 flex-direction-column">
                  <div className="flex gap-2 items-center"><span className="btn-sm-blu-light"><i className="las la-envelope"></i> </span> <span>Phone number</span> </div>
                  <div><a href="javascript:void(0);" className="bold-black-an">{user?.phone_number || "Not specified"}</a>
                  </div>
                </div>
                <div>
                  <a href="javascript:void(0);"></a>
                  <span className="btn-ch-sm"><i className={(user?.mobile_verified ?? false) ? "las la-check" : "las la-times"}></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="line-mid mt-5 mb-5"></div>

        <div className="mt-5 mb-5 from-edit-click">
          <h5>Social media</h5>
          <div className="socal-list-nav gap-5">
            <div className="list-socal">
              <div>
                <div className="flex gap-0 flex-direction-column">
                  <div className="flex gap-2 items-center"><span className="btn-sm-blu-light"><svg width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_9_2)">
                      <path d="M400 800C620.914 800 800 620.914 800 400C800 179.086 620.914 0 400 0C179.086 0 0 179.086 0 400C0 620.914 179.086 800 400 800Z" fill="#273B7A" />
                      <path d="M790.998 484.633L546.433 240.064L342.537 256.339L238.384 351.417L251.75 558.241L483.316 791.283C636.922 758.736 757.947 638.047 790.998 484.633Z" fill="#121149" />
                      <path d="M408.725 363.233V439.881H528.595C511.595 494.75 460.458 534.605 400.004 534.605C325.664 534.605 265.397 474.341 265.397 399.997C265.394 325.659 325.658 265.392 399.998 265.392C434.74 265.392 466.304 278.669 490.186 300.277L546.431 240.061C507.848 204.719 456.448 183.137 399.998 183.137C280.229 183.137 183.136 280.228 183.136 400C183.136 519.772 280.229 616.862 400 616.862C519.77 616.862 616.862 519.77 616.862 400C616.862 387.453 615.704 375.189 613.644 363.233H408.725Z" fill="#4285F4" />
                      <path d="M400 265.395C434.742 265.395 466.306 278.672 490.188 300.28L546.433 240.064C507.85 204.719 456.45 183.137 400 183.137C316.942 183.137 244.827 229.85 208.398 298.416L274.58 351.417C294.083 301.12 342.814 265.395 400 265.395Z" fill="#EA4335" />
                      <path d="M265.399 400C265.399 382.847 268.73 366.505 274.581 351.419L208.4 298.417C192.299 328.725 183.139 363.288 183.139 400.002C183.139 436.884 192.375 471.603 208.616 502.011L274.405 448.098C268.674 433.139 265.399 416.97 265.399 400Z" fill="#FBBC05" />
                      <path d="M474.836 511.792C453.426 526.155 427.722 534.606 400.004 534.606C342.631 534.606 293.776 498.65 274.401 448.095L208.612 502.008C245.115 570.344 317.114 616.861 399.998 616.861C453.828 616.861 503.034 597.206 540.947 564.739L474.836 511.792Z" fill="#34A853" />
                      <path d="M616.863 400C616.863 387.453 615.705 375.189 613.644 363.233H408.725V439.881H528.595C519.391 469.594 500.119 494.828 474.836 511.792L540.949 564.741C587.394 524.964 616.863 465.942 616.863 400Z" fill="#4285F4" />
                    </g>
                    <defs>
                      <clipPath id="clip0_9_2">
                        <rect width="800" height="800" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  </span> <span>Google</span> </div>
                  <div><a href="javascript:void(0);" className="bold-black-an">{user?.user_email || "Not specified"}</a>
                  </div>
                </div>
                <div>
                  <a href="javascript:void(0);">{(user?.social_google_login ?? false) ? "Unlink" : "Link"}</a>
                  <span className="btn-ch-sm"><i className={(user?.social_google_login ?? false) ? "las la-check" : "las la-times"}></i></span>
                </div>
              </div>
            </div>

            <div className="list-socal">
              <div>
                <div className="flex gap-0 flex-direction-column">
                  <div className="flex gap-2 items-center"><span className="btn-sm-blu-light"><svg width="669" height="664" viewBox="0 0 669 664" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="334.5" cy="332" rx="334.5" ry="332" fill="white" />
                    <path d="M333.333 0C150 0 0 149.667 0 334.001C0 500.667 122 639.001 281.333 664.001V430.667H196.667V334.001H281.333V260.333C281.333 176.667 331 130.667 407.333 130.667C443.667 130.667 481.667 137 481.667 137V219.333H439.667C398.333 219.333 385.333 245 385.333 271.334V334.001H478L463 430.667H385.333V664.001C463.88 651.594 535.407 611.517 586.997 551.001C638.587 490.487 666.847 413.521 666.667 334.001C666.667 149.667 516.667 0 333.333 0Z" fill="#3B5998" />
                  </svg>
                  </span> <span>Phone number</span> </div>
                  <div><a href="" className="bold-black-an">{user?.phone_number || "Not specified"}</a>
                  </div>
                </div>
                <div>
                  <a href="">{(user?.social_facebook_login ?? false) ? "Unlink" : "Link"}</a>
                  <span className="btn-ch-sm"><i className={(user?.social_facebook_login ?? false) ? "las la-check" : "las la-times"}></i></span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
  return (
    <div className="bgcolor-light pb-14 pt-10">
      <Helmet title="Tiny Away | Account"></Helmet>
      <div className="container">
        <div className="row-profile  mt-5 mb-5">
          <CommonLayout Displayname={details.display_name} />
          {AccountPageMidSection()}

        </div>
      </div>
    </div>
  );
};

export default AccountPage;
