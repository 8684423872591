import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionBecomeHostVideo from "./SectionBecomeHostVideo";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { TaxonomyType } from "data/types";
import SectionLandHostCommunity from "components/SectionLandHostCommunity/SectionLandHostCommunity";
import axios from 'axios';
import Selectss from "react-select";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import SectionAibnbReviews from "components/SectionAibnbReviews/SectionAibnbReviews";
import { useForm,Controller } from "react-hook-form";
import BecomeAHostContactForm from "components/BecomeAHostContactForm/BecomeAHostContactForm";


const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/BI_blue_background_vertical.png",
  },
  {
    id: "2",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/761ec63ba823cd88446152f20d7d8183.png",
  },
  {
    id: "3",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/7-NEWS-black.png",
  },
  {
    id: "4",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Stuff.png",
  },
  {
    id: "5",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Urban-List.png",
  },
  {
    id: "6",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/timeout-cs-logo-img-1.jpg",
  },
  {
    id: "7",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/the-weekend-Australian-magazine-1.png",
  },
  {
    id: "8",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/daily-telegraph-logo-vector-i1-1.png",
  },
  {
    id: "9",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/tfe-logo-768x123-2.png",
  }
];


export interface PageBecomeHostProps {
  className?: string;
  autoFocus?: boolean;
}
const PageBecomeHost: FC<PageBecomeHostProps> = ({
  className = "",
  autoFocus = false
}) => {
  
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [selectedOption, setSelectedOption] = useState();
  const [optionList, setOptionList] = useState([{ value: "id", label: "Red1" }]);
  const [message, setMessage] = useState("");
  const section1Ref = useRef<HTMLDivElement | null>(null);
  // Function to scroll to a specific section
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + 'getCountryList')
      .then(res => {
        setOptionList(res.data.response);
      })
  }, [selectedOption]);
 
  const renderHearingFromYou = () => {
    return (
      <div className="relative" ref={section1Ref}>
        <img className="top-5" src="https://pictures.tinyaway.com/filters:format(jpg)/1920x800/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg" />
        <div className="hearing-from-content container">
          <div className="left-part-hedding mt-100">
            <h4>FIND OUT HOW<br/> MUCH YOU CAN<br/> EARN FROM<br/> HOSTING</h4>
            <p>If you have any questions or feedback, we're here to help.</p>
          </div>
  
          <div className="right-part-hedding mt-16">
            <div className="flex justify-center">
              <ButtonPrimary type="submit" className="btn-box-form"><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contactform_top_icon.svg" /></ButtonPrimary>
            </div>
            <div className="hearing-form-group">
              <h5>To Discover Nature and Stay in Comfort.</h5>
              <BecomeAHostContactForm optionList={optionList} setMessage={setMessage} />
              {message && <p className="text-center text-blue-600 mt-2">{message}</p>}
            </div>
          </div>
        </div>
        <div className="border-2 w-full clint-logo-about">
          <div className="container">
          <SectionAibnbReviews
            heading="featured In "
            subHeading=""
            className="hedding-center sm-hedding-text hedding-aboutus"
            categoryCardType="card6"
            categories={DEMO_CATS}
            itemPerRow={4}
          />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={`nc-PageBecomeHost overflow-hidden relative ${className}`} data-nc-id="PageBecomeHost">
      <Helmet title={'Become a Host - Tiny Away | Land Partners for Tiny Houses | Host a Tiny'}></Helmet>
      {/* ======== BG GLASS ======== */}

      {window.location.hostname == 'tinyaway.com.tw' || window.location.hostname == 'www.tinyaway.com.tw' || window.location.hostname == 'localhost' || window.location.hostname == 'sandbox.tinyaway.com' || window.location.hostname == 'www.sandbox.tinyaway.com'  || window.location.hostname == 'tinyaway.com' || window.location.hostname == 'www.tinyaway.com' ?
        <>

          <div className=" w-full">
            <div className="tw-banner ">
              <img className="w-full" src="https://pictures.tinyaway.com/filters:format(jpg)/1920x720/becomeahost/top_banner_img.jpg" alt=""></img>
              <div className="main-about-banner">
                <div>
                  <h1><span>BECOME A HOST</span></h1>
                  <p>Bring your property to life and help people find soul-fulfilling moments in nature.
                  </p>
                  <ButtonPrimary onClick={() => scrollToSection(section1Ref)}>GET STARTED</ButtonPrimary>
                </div>
              </div>

            </div>

          </div>


          <div className="contener">
            <div className="mid-box-B-host">
              <h3>DO YOU HAVE SPARE LAND</h3>
              <p>Tiny Away works towards disrupting the current tourism accommodation – in an eco-friendly, sustainable and highly efficient kind of way. We are working hard to reconnect people with nature and curate the perfect escape from a hectic and digitally-laden lifestyle. </p>
              <p>We are expanding. We are looking for hosts to our tiny houses. Every property is unique and we want people to experience the magic of yours. Join us in the journey to inspire people to get out of the city and enjoy fulfilling moments in nature. </p>
              <p>Get in touch with us and receive an information pack on hosting a tiny house. We will also let you know how much you can earn from hosting. No obligations.</p>
            </div>
          </div>

          <div className="why-become-a-host">
            <div className="container flex mb-16 mt-16">
              <div className="w-30">
                <h4>WHY BECOME A HOST</h4>
                <ul className="list-icon-img">
                  <li> <a href=""><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-30.svg" /> No Capital Required </a></li>
                  <li> <a href=""><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-31.svg" />  Flexibility </a></li>
                  <li> <a href=""><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-32.svg" />   Make New Friends </a></li>
                  <li> <a href=""><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-33.svg" />   Be Your Best Host </a></li>
                  <li> <a href=""><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-34.svg" />  Earn Rental Income</a></li>
                </ul>
              </div>
              <div className="w-70">
                <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 ">
                  <div>
                    <div className="card-list">
                      <div className="img-card-list"><img src="https://pictures.tinyaway.com/filters:format(jpg)/530x350/becomeahost/bch_img1.jpg"></img></div>
                      <div className="listing-text-card">
                        <div className="flex gap-2"><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-30.svg" /><h4>No Capital Required</h4></div>
                        <p>You do not need to put in any upfront capital to start this. To reinforce a sense of escapism, we will provide you with a fully furnished tiny house, with each one blending seamlessly into its natural surroundings and offering a local connection to the guest.</p>
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <div className="card-list">
                      <div className="img-card-list"><img src="https://pictures.tinyaway.com/filters:format(jpg)/530x350/becomeahost/bch_img2.jpg"></img></div>
                      <div className="listing-text-card">
                        <div className="flex gap-2"><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-31.svg" /><h4>Flexibility</h4></div>
                        <p>As our tiny house host, you have the option to receive a higher income by providing utilities (water, gas and electricity), and help to keep the house clean. You also have the flexibility to simply provide the land and we will do the rest.
                          </p> </div>
                    </div>
                  </div>

                  <div>
                    <div className="card-list">
                      <div className="img-card-list"><img src="https://pictures.tinyaway.com/filters:format(jpg)/530x350/becomeahost/bch_img3.jpg"></img></div>
                      <div className="listing-text-card">
                        <div className="flex gap-2"><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-32.svg" />  <h4>Make New Friends</h4></div>
                        <p>If you like meeting new friends from all over the world, this is perfect for you. As our tiny house hosts, you will be our key point of contact with guests and help ensure that they have an awesome stay.</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="card-list">
                      <div className="img-card-list"><img src="https://pictures.tinyaway.com/filters:format(jpg)/530x350/becomeahost/bch_img4.jpg"></img></div>
                      <div className="listing-text-card">
                        <div className="flex gap-2"><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-33.svg" /><h4>Be Your Best Host</h4></div>
                        <p>You’ve got the property and we’ve got the tools to help you host with confidence. We handle all the marketing, bookings and customer service. Together we curate a memorable experience at all stages of the guest journey.</p>
                      </div>
                    </div>
                  </div>

                   

                  <div>
                    <div className="card-list">
                      <div className="img-card-list"><img src="https://pictures.tinyaway.com/filters:format(jpg)/530x350/becomeahost/bch_img5.jpg"></img></div>
                      <div className="listing-text-card">
                        <div className="flex gap-2"><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_BecomeAHost-34.svg" /><h4>Earn Rental Income</h4></div>
                        <p>Best of all, you will get to earn from the rental of the tiny house while inspiring people to get out of the city and reconnect with nature. If you are running a business on the property, it complements and bring you more customers.</p>
                      </div>
                    </div>
                  </div>

                   
                </div>
              </div>
            </div>

          </div>

        </>
        :
        <>
          <div className="py-16 lg:py-18 space-y-16 lg:space-y-28 pt-0">
            {/* <SectionBecomeHostVideo /> */}
            <img src="https://cdn.tinyaway.com/common/20201013_Piccolino-full_05-2048x1366.jpg"></img>
          </div>
          <div className="container py-16 lg:py-18 space-y-16 lg:space-y-28 pt-0">
            <div className="our-tiny becomehost-content pl-50 pr-50">
              <div className="meet-our-sled">
                <SectionLandHostCommunity
                  heading="MEET OUR 
                LAND HOSTS COMMUNITY"
                  subHeading=""
                  className="hedding-center"
                  categoryCardType="card8"
                  categories={DEMO_CATS}
                  itemPerRow={3}
                />
              </div>
              <div className="content-becomehost">
                <h3 className="mt-70">DO YOU HAVE SPARE LAND</h3>
                <p className="pmb-20">Tiny Away works towards disrupting the current tourism accommodation – in an eco-friendly, sustainable and highly efficient kind of way. We are working hard to reconnect people with nature and curate the perfect escape from a hectic and digitally-laden lifestyle.</p>
                <p className="pmb-20">We are expanding. We are looking for hosts to our tiny houses. Every property is unique and we want people to experience the magic of yours. Join us in the journey to inspire people to get out of the city and enjoy fulfilling moments in nature.</p>
                <p className="pmb-20">Get in touch with us and receive an information pack on hosting a tiny house. We will also let you know how much you can earn from hosting. No obligations.</p>
                <h3 className="mt-70 text-left mb-2">Channel 7 Interview on Hosting a Tiny House</h3>
                <iframe width="100%" height="642" src="https://www.youtube.com/embed/G1dbehZk2g8" title="Tiny House Host Interview Series - Chatting with Helen, Saddle Camp at Braidwood" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
              </div>
              <div className="flex-list-number-content">
                <div className="list-text">
                  <div className="number-listing">
                    <span>01</span>
                  </div>
                  <div className="right-content">
                    <h4>NO CAPITAL REQUIRED</h4>
                    <p>You do not need to put in any upfront capital to start this. To reinforce a sense of escapism, we will provide you with a fully furnished tiny house, with each one blending seamlessly into its natural surroundings and offering a local connection to the guest.</p>
                  </div>
                </div>
                <div className="list-text">
                  <div className="number-listing">
                    <span>02</span>
                  </div>
                  <div className="right-content">
                    <h4>FLEXIBILITY</h4>
                    <p>As our tiny house host, you have the option to receive a higher income by providing utilities (water, gas and electricity), and help to keep the house clean. You also have the flexibility to simply provide the land and we will do the rest.</p>
                  </div>
                </div>
                <div className="list-text">
                  <div className="number-listing">
                    <span>03</span>
                  </div>
                  <div className="right-content">
                    <h4>MAKE NEW FRIENDS</h4>
                    <p>If you like meeting new friends from all over the world, this is perfect for you. As our tiny house hosts, you will be our key point of contact with guests and help ensure that they have an awesome stay.</p>
                  </div>
                </div>
                <div className="list-text">
                  <div className="number-listing">
                    <span>04</span>
                  </div>
                  <div className="right-content">
                    <h4>BE YOUR BEST HOST</h4>
                    <p>You’ve got the property and we’ve got the tools to help you host with confidence. We handle all the marketing, bookings and customer service. Together we curate a memorable experience at all stages of the guest journey.</p>
                  </div>
                </div>
                <div className="list-text">
                  <div className="number-listing">
                    <span>05</span>
                  </div>
                  <div className="right-content">
                    <h4>EARN RENTAL INCOME</h4>
                    <p>Best of all, you will get to earn from the rental of the tiny house while inspiring people to get out of the city and reconnect with nature. If you are running a business on the property, it complements and bring you more customers.</p>
                  </div>
                </div>
              </div>
              <div className="form-content-left">
                <h5 className="mt-100">Find out how much you can earn from hosting</h5>
                {successMsg &&
                  <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                    {successMsg}
                  </div>}
                {errorMsg &&
                  <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                    {errorMsg}</div>}

              </div>
              <h3 className="mt-70 text-left mb-2"> Channel 7 Interview on Hosting a Tiny House</h3>
              <iframe width="100%" height="642" src="https://www.youtube.com/embed/usnixd-5wPM" title="Channel 7 Interview with Tiny Away" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
            </div>
          </div>
        </>
      }

      {renderHearingFromYou()}
      <SectionSubscribe2 />
    </div>
  );
};
export default PageBecomeHost;
