import React, { FC,useState,useEffect,ComponentType, Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// import { Page } from "./types";

import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import OurRewords from "containers/AccountPage/OurRewords";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageGuides from "containers/PageGuides/PageGuides";
import PageAbout from "containers/PageAbout/PageAbout";
import PageFaqCancellationPolicy from "containers/PageAbout/PageFaqCancellationPolicy"
import PageFaq from "containers/PageFaq/PageFaq";
import PageReviewRating from "containers/PageReviewRating/PageReviewRating";
import AccommodationOptions from "containers/PageAbout/PageAccommodationOptions";
import PageCommon from "containers/PageCommon/PageCommon";
import PageRewards from "containers/PageRewards/PageRewards";
import PageBecomeHost from "containers/PageBecomeHost/PageBecomeHost";
import PageBecomeHostJapan from "containers/PageBecomeHost/PageBecomeHostJapan";
import PageBecomeHostEurope from "containers/PageBecomeHost/PageBecomeHostEurope";
import PageBecomeHostTaiwan from "containers/PageBecomeHost/PageBecomeHostTaiwan";
import Pagecovid19policy from "containers/Pagecovid19policy/Pagecovid19policy";
import Pageprivacy from "containers/Pageprivacy/Pageprivacy";
import Pagetermsofuse from "containers/Pagetermsofuse/Pagetermsofuse";
import PageOneTreePlanted from "containers/PageOneTreePlanted/PageOneTreePlanted";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import AccountReservations from "containers/AccountPage/AccountReservations";
import ThingsDetailPage from "containers/ThingsDetailPage/ThingsDetailPage";
import TravelInspirationsDetails from "containers/TravelInspirations/TravelInspirationsDetailPage";
import TravelInspirations from "containers/TravelInspirations/TravelInspirationsPage";
import TravelinpirationArea from "containers/TravelInspirations/TravelinpirationAreaPage";
import PostBookingCharges from "containers/PostBookingCharges/PostBookingCharges";
import PageSponsors from "containers/PageSponsors/PageSponsors";
import PageHostsTestimonials from "containers/PageHostsTestimonials/PageHostsTestimonials";
import PageWishlists from "containers/PageWishlists/PageWishlists";
import PageTransactions from "containers/AccountPage/AccountRewardsTransactions";
import PageGiftCardsRedeem from "containers/AccountPage/PageGiftCardsRedeem";
import PageReferralProgram from "containers/AccountPage/PageReferralProgram";
import PageMyPost from "containers/AccountPage/PageMyPost";
import PagePromoCode from "containers/AccountPage/PagePromoCode";
import PageWriteReview from "containers/AccountPage/PageWriteReview";
import PageCreateStory from "containers/AccountPage/PageCreateStory";

import ExtendedHolidayProgram from "containers/ExtendedHolidayProgram/ExtendedHolidayProgram";
import ExtendedPayPage from "containers/ExtendedPayPage/ExtendedPayPage";
import PageCorporateMembership from "containers/PageCorporateMembership/PageCorporateMembership";
import VerifyEmail from "components/VerifyEmail/VerifyEmail";
import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "AuthContext/AuthContext";
import { WishlistProvider } from "WishlistContext/WishlistContext";

import axios from 'axios';  

//export const pages: Page[] = [

export interface Page {}
export const pages = [
 
];
const publicRoutes = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/book-a-tiny-house", component: ListingStayPage },
  { path: "/tiny-houses-coming-soon", component: ListingStayPage },
  { path: "/escape", component: ListingStayPage },
  { path: "/escape/:pathParam1?/:pathParam2", component: ListingStayPage },
  { path: "/area/:slug", component: ListingStayPage },
  { path: "/city/:slug", component: ListingStayPage },
  { path: "/state/:slug", component: ListingStayPage },
  { path: "/country/:slug", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/search-results", component: ListingStayMapPage },
  { path: "/listing/:slug", component: ListingStayDetailPage },
  { path: "/tiny-house-stories",component: ListingExperiencesPage},
  { path: "/tiny-house-stories-map",component: ListingExperiencesMapPage},
  { path: "/story/:slug",component: ListingExperiencesDetailPage},
  { path: "/things/:slug",component: ThingsDetailPage},
  { path: "/travel/:slug",component: TravelInspirationsDetails},
  { path: "/travelinspirations",component: TravelInspirations},
  { path: "/traveiinpirationarea",component: TravelinpirationArea},
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  { path: "/author", component: AuthorPage },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  { path: "/contact-us", component: PageContact },
  { path: "/feedback", component: PageContact },
  { path: "/maintenance", component: PageContact },
  { path: "/about", component: PageAbout },
  {path: "/PageFaqCancellationPolicy", Component: PageFaqCancellationPolicy},
  { path: "/faqs", component: PageFaq },
  { path: "/writereviews", component: PageReviewRating },
  { path: "/accommodation-options", component: AccommodationOptions },
  { path: "/rewards", component: PageRewards },
  { path: "/onetreeplanted", component: PageOneTreePlanted },
  { path: "/terms-of-use", component: Pagetermsofuse },
  { path: "/privacy", component: Pageprivacy },
  { path: "/cancellation-policy", component: Pagecovid19policy },
  { path: "/host-a-tiny-house", component: PageBecomeHost },
  { path: "/become-a-host-in-japan", component: PageBecomeHostJapan },
  { path: "/become-a-host-taiwan", component: PageBecomeHostTaiwan },
  { path: "/become-a-host-europe", component: PageBecomeHostEurope },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/6sixteen-food-menu", component: PageGuides },
  { path: "/6sixteen-wood-fire-bath-tub-packages", component: PageGuides },
  { path: "/compost-toilet", component: PageGuides },
  { path: "/tinyhousefarmstay", component: PageGuides },
  { path: "/dreams-alpaca-farmstay-activities", component: PageGuides },
  { path: "/dandelion-retreat-welcome", component: PageGuides },
  { path: "/wood-burner-fireplace", component: PageGuides },
  { path: "/kooranung-menu", component: PageGuides },
  { path: "/littlemagiconmagicmountain-activities", component: PageGuides },
  { path: "/directions-to-maggies-farmstay", component: PageGuides },
  { path: "/serenity-abode-breakfast-menu", component: PageGuides },
  { path: "/tiny-inja-food-menu", component: PageGuides },
  { path: "/little-laurie-breakfast-hamper-menu", component: PageGuides },
  { path: "/steel-gates-offer-local-winery", component: PageGuides },
  { path: "/sutherland-estate-local-winery", component: PageGuides },
  { path: "/grampians-edge-caravan-park-rules", component: PageGuides },
  { path: "/yellow-rock-views-grazing-platters", component: PageGuides },
  { path: "/tiny-towrang-food-menu", component: PageGuides },
  { path: "/tiny-inja-kurrajong-food-menu", component: PageGuides },
  { path: "/tiny-house-farmstay-at-dreams-alpaca-farm", component: PageGuides },
  { path: "/the-saddle-camp-activities", component: PageGuides },
  { path: "/the-clyde-menu", component: PageGuides },
  { path: "/surrounding-fact-sheet-for-st-albans", component: PageGuides },
  { path: "/sungai-tiny-house-in-house-rules", component: PageGuides },
  { path: "/sungai-tiny-house-atv-charges", component: PageGuides },
  { path: "/steel-gates-local-winery", component: PageGuides },
  { path: "/spirit-bird-workshops", component: PageGuides },
  { path: "/spirit-bird-map", component: PageGuides },
  { path: "/odyssean-code-of-conduct", component: PageGuides },
  { path: "/nomad-nation-breakfast-menu", component: PageGuides },
  { path: "/naz-beach-shack-breakfast-and-lunch-food-menu", component: PageGuides },
  { path: "/naked-lady-wines-spring-food-menu", component: PageGuides },
  { path: "/naked-lady-wines-spring-drinks-menu", component: PageGuides },
  { path: "/naked-lady-wines-set-menu", component: PageGuides },
  { path: "/little-magic-on-magic-mountain-activities", component: PageGuides },
  { path: "/little-acorn-by-the-grove-food-and-activities-menu", component: PageGuides },
  { path: "/kooranung-activities", component: PageGuides },
  { path: "/electric-vehicle-charging", component: PageGuides },
  { path: "/drumour-food-menu", component: PageGuides },
  { path: "/dreams-alpaca-farm-activities", component: PageGuides },
  { path: "/directions-to-tiny-st-albans", component: PageGuides },
  { path: "/directions-to-maggies-farmstay", component: PageGuides },
  { path: "/directions-to-dam-it-getaway", component: PageGuides },
  { path: "/dandelion-retreat-welcome-sheet", component: PageGuides },
  { path: "/azaltie-food-menu", component: PageGuides },
  { path: "/6sixteen-the-banks-wood-fire-bath-tub-packages", component: PageGuides },
  { path: "/golden-hill-farm-food-menu", component: PageGuides },
  { path: "/golden-hill-farm-food-menud", component: PageGuides },
  { path: "/pages/:slug", component: PageCommon },
  { path: "/postbookingpayment", component: PostBookingCharges },
  { path: "/sponsors", component: PageSponsors },
  { path: "/hoststestimonials", component: PageHostsTestimonials },
  { path: "/transection", component: PageTransactions },
  { path: "/pagewritereview", component: PageWriteReview },
  { path: "/pagecreatestory", component: PageCreateStory },
  { path: "/extendedholiday/:pathParam1?/:pathParam2", component: ExtendedHolidayProgram },
  { path: "/extended-paydone/:pathParam1?/:pathParam2", component: ExtendedPayPage },
  { path: "/corporate-membership", component: PageCorporateMembership },
  { path: "/verify-email", component: VerifyEmail },
];

const protectedRoutes = [
  { path: "/account",exact: true, component: AccountPage , protected: true},
  { path: "/wishlists", component: PageWishlists },
  { path: "/account-password", component: AccountPass },
  { path: "/our-rewords", component: OurRewords },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  { path: "/reservations", component: AccountReservations , protected: true},
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done/:slug", component: PayPage },
  { path: "/promocode", component: PagePromoCode },
  { path: "/myposts", component: PageMyPost },
  { path: "/giftcardsredeem", component: PageGiftCardsRedeem },
  { path: "/Pagereferralprogram", component: PageReferralProgram },
];
const Routes: FC<Page> = () => {
//const Routes = () => {

const [defaultNavigation, setDefaultNavigation] = useState([...publicRoutes, ...protectedRoutes]);

// const [nagivationData, setNagivationData]             = useState("");
// useEffect(() => {
//     axios.get(process.env.REACT_APP_BASE_URL+'getPageComponent')
// .then(res => {
//   //setdefaultnegivation(res.data);
//   //setBannnerUrl(res.data.banners.banner_url);
//   //setIsVideo(res.data.banners.is_video);
// })
// }, []);
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <AuthProvider>
      <WishlistProvider>
      <Header />
      <Switch>
      
        {/* Public Routes */}
        {publicRoutes.map(({ component, path, exact }) => (
          <Route key={path} component={component} exact={!!exact} path={path} />
        ))}

        {/* Protected Routes (Require Authentication) */}
        {protectedRoutes.map(({ component, path, exact }) => (
          <ProtectedRoute key={path} component={component} exact={!!exact} path={path} />
        ))}

        {/* Catch-all for 404 */}
        <Route component={Page404} />
      
      </Switch>
      </WishlistProvider>
      </AuthProvider>
      <Footer />
    </BrowserRouter>
  );
};
export default Routes;
