// components/ContactForm.tsx

import React from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

interface Props {
  optionList: { value: string; label: string }[];
  setMessage: (msg: string) => void;
}

const BecomeAHostContactForm: React.FC<Props> = ({ optionList, setMessage }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/host_enquiries`,
        data,
        { headers: { "Content-Type": "application/json" } }
      );
    if (response.data.status === 200) {
        setMessage("✅ Form submitted successfully!");
        reset();
      } else {
        setMessage("❌ Failed to submit form. Please try again.");
      }
    } catch (error) {
      setMessage("❌ Error submitting form.");
    }
  };
  return (
    <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
      <label className="block">
        <Input
          type="text"
          placeholder="NAME"
          {...register("name", { required: "Name is required" })}
          id="name"
        />
        {errors.name && <p className="text-red-500">{errors.name.message}</p>}
      </label>
      <label className="block">
        <Input
          type="email"
          placeholder="EMAIL"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email format",
            },
          })}
          id="email"
        />
        {errors.email && <p className="text-red-500">{errors.email.message}</p>}
      </label>
      <label className="block">
        <Input
          type="text"
          placeholder="CONTACT NUMBER"
          {...register("phone", {
            required: "Contact number is required",
            minLength: {
              value: 8,
              message: "Contact number must be at least 8 digits",
            },
          })}
          id="phone"
        />
        {errors.phone && <p className="text-red-500">{errors.phone.message}</p>}
      </label>
      <label className="block">
        <Controller
          name="country"
          control={control}
          rules={{ required: "Country is required" }}
          render={({ field }) => (
            <Select {...field} id="country">
              <option value="">COUNTRY</option>
              {optionList.map((country) => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </Select>
          )}
        />
        {errors.country && <p className="text-red-500">{errors.country.message}</p>}
      </label>
      <label className="block">
        <Textarea
          placeholder="YOUR MESSAGE"
          {...register("message", { required: "Message is required" })}
          id="message"
        />
        {errors.message && <p className="text-red-500">{errors.message.message}</p>}
      </label>
      <div className="text-center">
        <ButtonPrimary type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default BecomeAHostContactForm;
