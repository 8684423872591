import React, { FC, useState } from 'react';
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionAibnbReviews from "components/SectionAibnbReviews/SectionAibnbReviews";
import { TaxonomyType } from "data/types";
import { Controller, useForm } from "react-hook-form";
import axios from 'axios';

const DEMO_CATS: TaxonomyType[] = [
    {
      id: "1",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/BI_blue_background_vertical.png",
    },
    {
      id: "2",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/761ec63ba823cd88446152f20d7d8183.png",
    },
    {
      id: "3",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/7-NEWS-black.png",
    },
    {
      id: "4",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/Stuff.png",
    },
    {
      id: "5",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/Urban-List.png",
    },
    {
      id: "6",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/timeout-cs-logo-img-1.jpg",
    },
    {
      id: "7",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/the-weekend-Australian-magazine-1.png",
    },
    {
      id: "8",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/daily-telegraph-logo-vector-i1-1.png",
    },
    {
      id: "9",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/tfe-logo-768x123-2.png",
    }
  ];

export interface SectionFeedbackForm {
  className?: string;
}

const SectionFeedbackForm: FC<SectionFeedbackForm> = ({ className = "" }) => {
  const [message, setMessage] = useState("");
      const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors, isSubmitting },
      } = useForm();

      const onSubmit = async (data: any) => {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/customer_enquiries`,
              data,
              { headers: { "Content-Type": "application/json" } }
            );
          if (response.data.status === 200) {
              setMessage("✅ Form submitted successfully!");
              reset();
            } else {
              setMessage("❌ Failed to submit form. Please try again.");
            }
          } catch (error) {
            setMessage("❌ Error submitting form.");
          }
        };

    const [details, setDetails] = useState({
        name: "",
        email: "",
        contactnumber: "",
        subject: "Keep in Touch with Tiny Away",
        message: "",
    });
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
  return (
    <div className="relative">
    <img className="top-5" src="https://pictures.tinyaway.com/filters:format(jpg)/1920x800/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg" />
    <div className="hearing-from-content container">
      <div className="left-part-hedding mt-100">
        <h4>WE LOVE HEARING FROM YOU</h4>
        <p>If you have any questions or feedback, we're here to help.</p>
      </div>
      <div className="right-part-hedding mt-16">
        <div className="flex justify-center">
          <ButtonPrimary type="submit" className="btn-box-form"><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contactform_top_icon.svg" /></ButtonPrimary>
        </div>
        <div className="hearing-form-group">
          <h5>To Discover Nature and Stay in Comfort.</h5>
          <form className="grid grid-cols-1 gap-6" action="#" onSubmit={handleSubmit(onSubmit)}  autoComplete="off" method="post">
            <label className="block">
              <Input
               type="text"
               placeholder="NAME"
               {...register("name", { required: "Name is required" })}
               id="name"
              />
             {errors.name && <p className="text-red-500">{errors.name.message}</p>}
            </label>
            <label className="block">
              <Input
                 type="email"
                 placeholder="EMAIL"
                 {...register("email", {
                   required: "Email is required",
                   pattern: {
                     value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                     message: "Invalid email format",
                   },
                 })}
                 id="email"
              />
             {errors.email && <p className="text-red-500">{errors.email.message}</p>}
            </label>
            <label className="block">
              <Input
                 type="text"
                 placeholder="CONTACT NUMBER"
                 {...register("phone", {
                   required: "Contact number is required",
                   minLength: {
                     value: 8,
                     message: "Contact number must be at least 8 digits",
                   },
                 })}
                 id="phone"
              />
              {errors.phone && <p className="text-red-500">{errors.phone.message}</p>}
            </label>
            <label className="block">
              <Input
                type="text"
                placeholder="SUBJECT"
                defaultValue="Keep in Touch with Tiny Away"
                {...register("subject", { required: "subject is required" })}
                id="subject"
              />
             {errors.subject && <p className="text-red-500">{errors.subject.message}</p>}
            </label>
            <label className="block">
            <Textarea
                placeholder="YOUR MESSAGE"
                {...register("message", { required: "Message is required" })}
                id="message"
              />
              {errors.message && <p className="text-red-500">{errors.message.message}</p>}
            </label>
            <div>
              {/* <ReCAPTCHA
                // size="invisible"
                sitekey={REACT_APP_SITE_KEY!}
                ref={captchaRef}
                id="google_captcha"
                onChange={SetRecaptchaVal}
              /> */} 
            </div>
            <div className="mb-0 text-center">
              <ButtonPrimary type="submit">Submit</ButtonPrimary>
            </div>
          </form>
          {message && <p className="text-center text-blue-600 mt-2">{message}</p>}
        </div>
      </div>
    </div>
    <div className="border-2 w-full clint-logo-about">
      <div className="container">
        <SectionAibnbReviews
          heading="featured In "
          subHeading=""
          className="hedding-center sm-hedding-text hedding-aboutus"
          categoryCardType="card6"
          categories={DEMO_CATS}
          itemPerRow={4}
        />
      </div>
    </div>
  </div>
  );
};

export default SectionFeedbackForm;
